import apolloClient from "../../../config/apolloClient";
import {
    IFlags,
    IFlagsEditables,
    IFlagsResponse,
} from "../../interfaces/flags.interface";
import { GetFlags, UpdateFlags } from "./flags.gql";

export const getFlags = async () => {
    const { data, errors } = await apolloClient.query<{
        GetFlags: IFlagsResponse;
    }>({
        query: GetFlags,
        fetchPolicy: "network-only",
    });

    if (errors) {
        return { errors };
    }

    return { response: data.GetFlags };
};

export const updateFlags = async (values: IFlagsEditables) => {
    const { data, errors } = await apolloClient.mutate<{
        UpdateFlags: IFlags;
    }>({
        mutation: UpdateFlags,
        variables: { input: values },
    });

    if (errors) {
        return { errors };
    }

    return { response: data?.UpdateFlags };
};
