import React from "react";
import styles from "./index.module.scss";
import banner1img from "./../../../assets/images/parent-registration/1.png";
import signUpButtonGreen from "./../../../assets/images/parent-registration/parentlandingpage5-06.png";
import banner2img from "./../../../assets/images/parent-registration/2.png";
import banner3img from "./../../../assets/images/parent-registration/3.png";
import empowerBanner from "./../../../assets/images/parent-registration/parentlandingpage5-12.png";
import gamesBanner from "./../../../assets/images/parent-registration/parentlandingpage5-14.png";
import dashedImage from "./../../../assets/images/dashed-orange.png";
import unsureTitleImg from "./../../../assets/images/parent-registration/parentlandingpage5-15.png";
import mobilePreview1img from "./../../../assets/images/parent-registration/parentlandingpage5-16.png";
import mobilePreview2img from "./../../../assets/images/parent-registration/parentlandingpage5-17.png";
import mobilePreview3img from "./../../../assets/images/parent-registration/parentlandingpage5-18.png";
import worriedBorder from "./../../../assets/images/parent-registration/parentlandingpage5-28.png";
import worriedBanner from "./../../../assets/images/parent-registration/parentlandingpage5-26.png";
import banner8 from "./../../../assets/images/parent-registration/8.png";
import signUpButtonOrange from "./../../../assets/images/parent-registration/parentlandingpage5-22.png";
import NotifyMe from "../../shared/components/NotifyMe";
import sectionTwoBGImage from "./../../../assets/images/section-two-bg.png";
import { Link } from "react-router-dom";
import Testimonials from "./components/Testimonials";
import { AUTH_ROUTES } from "../../shared/routes/Constants";
import { getHeightForNotifyMe } from "../../shared/utils/get-height";

const ParentRegistration = () => {
    return (
        <>
            <section className="bg-aha-black py-6 md:py-0">
                <div className="md:flex container">
                    <div className="bg-aha-black text-white md:w-2/5 grid place-items-center">
                        <div className="space-y-6 w-4/5">
                            <div className="text-center text-2xl lg:text-3xl xl:text-5xl font-century-gothic-bold space-y-2">
                                <div>How's speech</div>
                                <div>practice going</div>
                                <div>at home?</div>
                            </div>
                            <div>
                                <Link to={`${AUTH_ROUTES.REGISTER}`}>
                                    <img src={signUpButtonGreen} alt="" />
                                </Link>
                            </div>
                            <div className="text-center text-xs tracking-widest">
                                NO CREDIT CARD REQUIRED
                            </div>
                        </div>
                    </div>
                    <div className="md:w-3/5">
                        <img
                            src={banner1img}
                            alt="Banner 1"
                            className="w-full object-contain pt-6 md:pt-0"
                        />
                    </div>
                </div>
            </section>
            <section className="container">
                <img src={banner2img} alt="" />
            </section>
            <section className="bg-aha-black py-6 md:py-0">
                <div className="md:flex container">
                    <div className="md:w-3/5">
                        <img
                            src={banner3img}
                            alt="Banner 3"
                            className="w-full object-contain"
                        />
                    </div>
                    <div className="bg-aha-black text-white md:w-2/5 grid place-items-center py-6 md:py-0">
                        <div className="space-y-6 w-4/5">
                            <div className="text-center text-5xl font-century-gothic-bold space-y-4">
                                <div>At Home</div>
                                <div>Articulation</div>
                                <div>
                                    <img src={empowerBanner} alt="" />
                                </div>
                                <div>parents</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container text-center p-12">
                <div className="md:w-4/5 mx-auto ">
                    <div className="mx-auto">
                        <p className="text-center font-bold text-3xl lg:text-6xl text-aha-green mb-5 tracking-wider">
                            Tired of the fight?
                        </p>
                        <img src={dashedImage} alt="" />
                    </div>
                    <p className="text-gray-500 pt-4 text-xl xl:text-2xl">
                        We designed speech practice games that kids actually
                        want to play!
                    </p>
                    <div className="pt-12">
                        <img src={gamesBanner} alt="" />
                    </div>
                </div>
            </section>
            <section className="p-16 bg-aha-light-blue">
                <div className="md:w-3/5 mx-auto space-y-4">
                    <div className="flex justify-center">
                        <img src={unsureTitleImg} alt="" />
                    </div>
                    <div className="text-center font-century-gothic-bold tracking-wider text-xl text-aha-black">
                        At home articulation provides:
                    </div>

                    <div className="flex flex-col md:flex-row items-center justify-center gap-12 pt-4">
                        <div>
                            <img src={mobilePreview1img} alt="" />
                        </div>
                        <div className="space-y-8">
                            <div>
                                <img src={mobilePreview2img} alt="" />
                            </div>
                            <div>
                                <img src={mobilePreview3img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="p-12 bg-contain bg-center bg-no-repeat "
                style={{
                    backgroundImage: `url(${worriedBorder})`,
                }}
            >
                <div className="md:w-3/5 mx-auto mb-4 flex justify-center">
                    <p className="text-center font-bold text-2xl lg:text-3xl xl:text-4xl text-aha-green mb-2 tracking-wider">
                        Worried you don't have time for this?
                    </p>
                </div>
                <div className="md:w-3/5 mx-auto text-xl xl:text-2xl relative text-aha-black">
                    <div className="md:w-2/4 text-center md:absolute ">
                        <div>Yes you do.</div>
                        <div>
                            It only takes{" "}
                            <span className="text-aha-orange">5 minutes</span> a
                            day.
                        </div>
                    </div>

                    <img src={worriedBanner} alt="" />

                    <div className="md:w-2/4  md:absolute right-0 bottom-16 text-center">
                        And you can do it{" "}
                        <span className="text-aha-orange">anywhere!</span>
                    </div>
                </div>
            </section>

            <Testimonials />

            <section className="bg-aha-light-blue-500">
                <div className="container space-x-14 lg:flex xl:space-x-0">
                    <div className="w-3/5 m-auto mb-10 xl:w-2/5 lg:mb-0">
                        <img
                            src={banner8}
                            alt="Banner 1"
                            className="w-full object-contain"
                        />
                    </div>
                    <div className="bg-aha-light-blue-500 grid place-items-center py-6 lg:py-0 lg:w-3/5">
                        <div className="space-y-4 w-5/5 xl:w-3/5">
                            <div>
                                <Link to={`${AUTH_ROUTES.REGISTER}`}>
                                    <img src={signUpButtonOrange} alt="" />
                                </Link>
                            </div>
                            <div className="text-center text-xs tracking-widest">
                                NO CREDIT CARD REQUIRED
                            </div>
                            <div className="text-center text-aha-black font-century-gothic-bold tracking-widest">
                                <div className="text-xl">
                                    What happens after 2 weeks?
                                </div>
                                <div className="text-lg">
                                    For continued access to premium features:
                                </div>
                            </div>
                            <div>
                                <div className="md:flex space-y-6 md:space-y-0 gap-6">
                                    <div className="bg-white text-center md:w-2/4 flex-shrink-0">
                                        <div className="px-4 py-8">
                                            <div className="mb-6">
                                                <div className="text-aha-orange font-blanch-bold tracking-widest text-5xl">
                                                    Monthly
                                                </div>
                                                <div className="tracking-wider">
                                                    subscription
                                                </div>
                                            </div>
                                            <div className="text-4xl mb-6">
                                                $11.99/mo
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white text-center md:w-2/4 flex-shrink-0">
                                        <div className="px-4 py-8">
                                            <div className="mb-6">
                                                <div className="text-aha-orange font-blanch-bold tracking-widest text-5xl">
                                                    Yearly
                                                </div>
                                                <div className="tracking-wider">
                                                    subscription
                                                </div>
                                            </div>
                                            <div className="text-4xl mb-6">
                                                $8/mo
                                            </div>
                                            <div className="mb-6">
                                                billed anually
                                                <br /> at $96
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-12 bg-aha-light-blue ">
                <NotifyMe invertedTheme />

                <div
                    style={{
                        backgroundImage: `url(${sectionTwoBGImage})`,
                        minHeight: getHeightForNotifyMe(),
                    }}
                    className={"bg-cover bg-no-repeat bg-bottom"}
                ></div>
            </section>
        </>
    );
};

export default ParentRegistration;
