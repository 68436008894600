import React, { useEffect, useState } from "react";
import { IDiscountCode } from "../../shared/interfaces/discount-code.interface";
import { ISelectedProduct } from "../../shared/interfaces/selected-product.interface";
import { getAllDiscountCode } from "../../shared/services/stripe/stripe.service";

export interface IOnDiscountCode {
    name: string;
    amount: string;
}

interface IProps {
    selectedProduct: ISelectedProduct;
    onDiscountCode: (params: IOnDiscountCode | null) => void;
}

const DiscountCodeSection = (props: IProps) => {
    const { onDiscountCode, selectedProduct } = props;

    const [coupon, setCoupon] = useState("");
    const [allCoupons, setAllCoupons] = useState<IDiscountCode[]>([]);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const formatString = (value: string | undefined) => {
        if (value) {
            return `${value.slice(0, value.length - 2)}.${value.slice(
                value.length - 2
            )}`;
        }
    };

    const getAmountWithDiscount = (discountCode: IDiscountCode) => {
        if (selectedProduct.price) {
            let value = discountCode.amount_off;
            if (discountCode.percent_off || discountCode.percent_off > 0) {
                value =
                    selectedProduct.price.unit_amount -
                    (selectedProduct.price.unit_amount *
                        discountCode.percent_off) /
                        100;
            } else if (discountCode.amount_off || discountCode.amount_off > 0) {
                value =
                    selectedProduct.price.unit_amount - discountCode.amount_off;
            }
            return Math.round(value).toString();
        }
    };

    const checkCoupon = () => {
        setSuccess("");
        setError("");
        onDiscountCode(null);
        if (coupon) {
            const couponExist = allCoupons.find((c) => c.name === coupon);

            if (!couponExist) {
                setError("Coupon does not exist.");
                return;
            }

            if (!couponExist.valid) {
                setError("Invalid coupon.");
                return;
            }

            const productsIds = couponExist.applies_to?.products;

            if (productsIds && productsIds.length) {
                const validForProduct = productsIds.find(
                    (id) => id === selectedProduct.product.id
                );
                if (!validForProduct) {
                    setError("Invalid coupon for this product.");
                    return;
                }
            }

            const newAmount = formatString(getAmountWithDiscount(couponExist));

            if (newAmount) {
                onDiscountCode({
                    name: coupon,
                    amount: newAmount,
                });
                setSuccess("Valid promo code");
            }
        }
    };

    useEffect(() => {
        getAllDiscountCode().then((res) => {
            setAllCoupons(res.response);
        });
    }, []);

    useEffect(() => {
        checkCoupon();
    }, [coupon]);

    return (
        <div className="relative">
            <input
                placeholder="Promo code"
                className={
                    "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                }
                type="text"
                value={coupon}
                onChange={(e) => {
                    setCoupon(e.target.value);
                }}
                maxLength={20}
            />

            {error && !success && <p className={"text-red-300"}>{error}</p>}
            {success && !error && <p className={"text-green-300"}>{success}</p>}
        </div>
    );
};

export default DiscountCodeSection;
