import apolloClient from "../../../config/apolloClient";
import { IUser } from "../../interfaces/user.interface";
import { STORAGE_KEYS } from "../../routes/Constants";
import { UpdateUser } from "../user/user.gql";
import { toast } from "react-toastify";
import {
    exchangeRefreshTokenMutation,
    getHubspotTokenMutation,
    loggedInUserQuery,
    loggedUserWithStudentQuery,
    loginFromAdminMutation,
    loginMutation,
    registerMutation,
    requestChangePasswordMutation,
    requestNewPasswordMutation,
} from "./auth.gql";

interface ILoginPayload {
    email: string;
    password: string;
}

interface IRegisterPayload extends ILoginPayload {
    firstname: string;
    lastname: string;
}

export const clearLocalStorage = () => {
    const instructionStateBakeACake = localStorage.getItem(
        "instructionStateBakeACake"
    );
    const instructionStateBuildACar = localStorage.getItem(
        "instructionStateBuildACar"
    );
    const gameSettings = localStorage.getItem(
        STORAGE_KEYS.GAME_SETTINGS
    );
    
    localStorage.clear();
    localStorage.setItem(
        "instructionStateBakeACake",
        instructionStateBakeACake || ""
    );
    localStorage.setItem(
        "instructionStateBuildACar",
        instructionStateBuildACar || ""
    );
    localStorage.setItem(
        STORAGE_KEYS.GAME_SETTINGS,
        gameSettings || ""
    );
};

export const loggedInUserRequest = async () => {
    const { data, errors } = await apolloClient.query({
        query: loggedInUserQuery,
    });

    if (errors) {
        return { errors };
    }

    return { response: data.LoggedInUser };
};

export const loggedUserWithStudent = async () => {
    const { data, errors } = await apolloClient.query({
        query: loggedUserWithStudentQuery,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
    });

    if (errors) {
        return { errors };
    }

    return { response: data.findUserWithStudentsRelated };
};

export const setParentProfile = async (profileStudentUsed: string | null) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: UpdateUser,
        variables: {
            updateUserInput: {
                profileStudentUsed
            },
        },
    });

    if (errors) {
        toast.error(errors[0]?.message || 'Something went wrong');
        throw new Error(errors[0]?.message || 'Something went wrong');
    }

    return data.updateUser;
};

export const loginRequest = async (payload: ILoginPayload) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: loginMutation,
        variables: {
            input: {
                ...payload,
                origin: "WEB",
            },
        },
    });

    if (errors) {
        return { errors };
    }

    return { response: data.Login };
};

export const loginFromAdminRequest = async (token: string) => {
    const { data, errors } = await apolloClient.mutate<{
        LoginFromWeb: {
            accessToken: string;
            refreshToken: string;
            user: IUser;
        };
    }>({
        mutation: loginFromAdminMutation,
        variables: {
            input: {
                token,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data?.LoginFromWeb };
};

export const exchangeRefreshTokenRequest = async (refreshToken: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: exchangeRefreshTokenMutation,
        variables: {
            input: {
                refreshToken,
            },
        },
    });

    if (errors) {
        return { errors };
    }

    return { response: data.ExchangeRefreshToken };
};

export const registerRequest = async (payload: IRegisterPayload) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: registerMutation,
        variables: {
            input: {
                ...payload,
            },
        },
    });

    if (errors) {
        return { errors };
    }

    return data.Registration;
};

export const newPasswordRequest = async (email: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: requestNewPasswordMutation,
        variables: {
            input: {
                email,
            },
        },
    });

    if (errors) {
        return { errors };
    }

    return { response: data.RequestNewPassword };
};

export const changePasswordRequest = async (payload: {
    token: string;
    newPassword: string;
}) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: requestChangePasswordMutation,
        variables: {
            input: {
                ...payload,
            },
        },
    });

    if (errors) {
        return { errors };
    }

    return { response: data.ChangePassword };
};

export const getHubspotToken = async (payload: {
    email: string;
    firstName: string;
    lastName: string;
}) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: getHubspotTokenMutation,
        variables: {
            input: {
                ...payload,
            },
        },
    });

    if (errors) {
        return { errors };
    }

    return { response: data.getHubspotToken };
};
