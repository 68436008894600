import React, { useState } from "react";
import closeImage from "./../../../assets/images/bake-a-cake/close.png";
import Modal from "react-modal";
import ReactModal from "react-modal";
import { ISoundSelectorModalOption } from "../../shared/interfaces/modal-option.interface";
import greenInfoIconImage from "./../../../assets/images/green-info.png";
import lockIcon from "./../../../assets/images/lock.png";
import { ISound } from "../../shared/interfaces/sound.interface";
import { COLORS } from "../../shared/utils/colors";

ReactModal.defaultStyles.overlay.backgroundColor = "#00000060";

const customStyles = {
    content: {
        top: "30%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -30%)",
        width: 500,
        minHeight: 500,
        padding: "1rem",
        border: "10px solid",
        borderColor: COLORS.aha_black,
    },
};

const SoundSelectorModal = ({
    showModal,
    onClose,
    selectedSound,
    childSelectedSound,
    lockSubscription,
    onLockClick,
}: ISoundSelectorModalOption) => {
    const [showInfo, setShowInfo] = useState(false);

    const renderChild = (sound: ISound) => {
        return (
            <div
                key={sound.id}
                className={`${childSelectedSound?.id === sound?.id ? 'bg-aha-orange' : 'bg-aha-black'} justify-self-center  rounded-full h-16 w-16 text-white flex flex-col items-center justify-center hover:bg-aha-orange cursor-pointer transition ease-in-out duration-200 relative
        ${lockSubscription && sound.subscription ? "cursor-not-allowed" : null}
        `}
                onClick={() => {
                    if (!(lockSubscription && sound.subscription)) {
                        onClose(sound);
                    } else {
                        onLockClick();
                    }
                }}
            >
                {lockSubscription && sound.subscription ? (
                    <div className="absolute -top-2 -right-1 h-6 w-6 pointer-events-none z-50">
                        <img src={lockIcon} alt="Lock" />
                    </div>
                ) : null}
                <div className={"tracking-widest font-century-gothic-bold"}>
                    {sound.title}
                </div>
                {sound.subTitle && (
                    <div className={"text-xs -mt-2 tracking-widest"}>
                        {sound.subTitle}
                    </div>
                )}
            </div>
        );
    };

    return (
        <Modal isOpen={showModal} style={customStyles} ariaHideApp={false}>
            <div className="flex justify-end">
                <img
                    src={closeImage}
                    alt="Close Modal"
                    className="w-10 cursor-pointer"
                    onClick={() => {
                        if (showInfo) {
                            setShowInfo(false);
                            return;
                        }
                        onClose();
                    }}
                />
            </div>

            {!showInfo ? (
                <>
                {
                    selectedSound?.title === "R" && (
                        <div className="text-center">
                            Select the /r/ sound(s)
                            <br />
                            you would like to practice:
                        </div>
                    )
                }
                    <div
                        className="grid grid-rows-5 grid-flow-col font-blanch-regular text-3xl gap-6 my-4"
                        style={{ minHeight: 300 }}
                    >
                        {selectedSound?.children?.map((sound) => {
                            return renderChild(sound);
                        })}
                    </div>

                    {
                        selectedSound?.title === "R" && (
                            <div
                                className="flex items-center justify-end gap-2 cursor-pointer"
                                onClick={() => {
                                    setShowInfo(true);
                                }}
                            >
                                <span className="">Questions? </span>
                                <img
                                    src={greenInfoIconImage}
                                    alt="Info icon"
                                    className="w-4"
                                />
                            </div>
                        )
                    }
                </>
            ) : (
                <div className="space-y-4 px-4">
                    <div className="text-center text-xl border-b-2 border-aha-green pb-2">
                        Which /r/ should I pick?
                    </div>
                    <div className="leading-8 text-justify">
                        Ask your child's clinician which /r/ variation to
                        practice. Please be careful about the /r/! The /r/ sound
                        can be particularly difficult to teach. It requires
                        someone with a skilled ear and specific training. If
                        your child is unable to produce the /r/ sound, please
                        don't practice this at home yet. Contact your local
                        speech-language pathologist. They can do a full
                        assessment and guide you to which /r/ sound to practice
                        at home.
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default SoundSelectorModal;
