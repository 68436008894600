import React, { useEffect } from "react";
import leftCaretImg from "./../../../../assets/images/parent-registration/parentlandingpage5-20.png";
import rightCaretImg from "./../../../../assets/images/parent-registration/parentlandingpage5-21.png";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const mobile = window.innerWidth < 768;

const Testimonials = () => {
    useEffect(() => {}, []);

    return (
        <section className="bg-aha-green py-12">
            <div className="container">
                <div className="text-center text-4xl  text-aha-black font-semibold">
                    What parents and clinicians are saying...
                </div>

                <div className="flex items-center gap-6 py-6 w-5/5 xl:w-4/5 mx-auto">
                    <div>
                        <button className="swiper-plugin-navigation-prevEl">
                            <img
                                src={leftCaretImg}
                                alt=""
                                style={{
                                    width: 100,
                                }}
                            />
                        </button>
                    </div>

                    <Swiper
                        style={mobile ? { height: 1000 } : { height: 610 }}
                        spaceBetween={64}
                        slidesPerView={mobile ? 1 : 2}
                        className={mobile ? "w-5/5" : "w-4/5"}
                        modules={[Navigation]}
                        navigation={{
                            prevEl: ".swiper-plugin-navigation-prevEl",
                            nextEl: ".swiper-plugin-navigation-nextEl",
                        }}
                        loop={true}
                    >
                        <SwiperSlide>
                            <div
                                style={
                                    mobile ? { height: 1000 } : { height: 610 }
                                }
                                className="bg-white p-4 text-aha-black"
                            >
                                I’m a parent of a 3 year and this was so so
                                great! He doesn’t want to engage in speech
                                practice anymore, and hated being corrected.
                                This was a way for us to connect and practice
                                his sounds without him dreading “speech time.”
                                As the mom of a kiddo who struggles with speech
                                and sounds THANK YOU!!! This will change
                                everything for us!
                                <div className="ml-5 mt-2">-Parent user</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div
                                style={
                                    mobile ? { height: 1000 } : { height: 610 }
                                }
                                className=" bg-white p-4 text-aha-black"
                            >
                                My son is four, and speech has been slower
                                coming for him. He has struggled with
                                articulation especially. He likes making the
                                pizza best but can stay engaged with all the
                                games for usually around 10 minutes. We have
                                been using At Home Articulation pretty much
                                since its infancy, with varying frequency. And
                                it has absolutely helped him improve his speech.
                                The instructions are easy to understand, and I
                                really feel like, even as a parent, I can help
                                make a measurable difference. It is so exciting
                                to hear him say words he has struggled with. He
                                will tell me, "Mom! I learned! I can say it
                                now!" Sometimes when he encounters a word he
                                doesn't know during the day, he will say it
                                quietly to himself a few times until it sounds
                                right. Which really shows that this program is
                                giving him a tool to use whenever he needs to.
                                <div className="ml-5 mt-2">
                                    -Jocelyn Barton (Parent user)
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div
                                style={
                                    mobile ? { height: 1000 } : { height: 610 }
                                }
                                className=" bg-white p-4 text-aha-black"
                            >
                                I love At Home Articulation! My four-year-old
                                son has struggled with the /r/ sound for a while
                                but I haven’t wanted to stop and correct him
                                constantly. I've been to a lot of speech
                                pathology lessons for my other child and in
                                comparison, this was much more fun and
                                comfortable! He loved that this was a game and
                                so he didn’t feel threatened at all! He loved
                                building the car and was totally willing to
                                participate and it really helped him with his
                                /r/ sound! We were both really excited! He
                                really got the hang of it when I was modeling it
                                for him and he loved that he got to check off
                                the check marks himself. The music added a very
                                fun vibe and I loved the sleek visuals on the
                                screen. He is asking when he can "play these
                                games" again! Total win!
                                <div className="ml-5 mt-2">
                                    -Synthia Simonson (Parent user)
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div
                                style={
                                    mobile ? { height: 1000 } : { height: 610 }
                                }
                                className=" bg-white p-4 text-aha-black"
                            >
                                My daughter is just wrapping up a year of early
                                intervention speech therapy and will be
                                transitioning into articulation therapy. We have
                                been so focused on building vocabulary,
                                articulation now felt more technical and
                                intimidating. At Home Articulation has been the
                                perfect bridge for us to shift to working on
                                specific sounds. It gives easy and clear
                                direction for the parent and child. My older
                                daughter is often asking if she can play along
                                with us, and will even guide her little sister
                                through the different games and sound exercises.
                                Approachable learning at it's finest!
                                <div className="ml-5 mt-2">
                                    -Bethany Joseph (Parent user)
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div
                                style={
                                    mobile ? { height: 1000 } : { height: 610 }
                                }
                                className=" bg-white p-4 text-aha-black"
                            >
                                Being able to work on speech every day with my 4
                                year old has been a game changer. He has been
                                lateralizing and lisping his S sounds. I have
                                talked with some SLPs about what to do, but
                                nothing helped until we started At Home
                                Articulation. The tips that are given are so
                                helpful and during practice he is able to say
                                the sounds correctly! He loves having fun
                                interactive games to play while practicing so he
                                looks forward to it every day!
                                <div className="ml-5 mt-2">
                                    -Mindy Layton (Parent user)
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div
                                style={
                                    mobile ? { height: 1000 } : { height: 610 }
                                }
                                className=" bg-white p-4 text-aha-black"
                            >
                                I am a clinician. I am so excited about this
                                program! It has a great variety of games. I have
                                clients who are obsessed with cars so that game
                                is a motivating way to get them practicing their
                                sounds! My favorite part is being able to assign
                                specific homework for them to practice in a
                                matter of seconds!
                                <div className="ml-5 mt-2">-Clinician user</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div
                                style={
                                    mobile ? { height: 1000 } : { height: 610 }
                                }
                                className=" bg-white p-4 text-aha-black"
                            >
                                As a school based SLP working at a school K-12
                                with children with Autism, parents never seem to
                                have enough time or energy to do speech
                                homework! It seems like just one more thing to
                                do even though they greatly care about their
                                children's communication and speech. This is
                                such a great go to tool for parents of students
                                with speech sound errors! Easy to access
                                multiple speech sounds, combinations of sounds,
                                positions and levels for practice! Love the
                                variety of super fun and engaging games and
                                activities! Useful sound production and success
                                tips are found along the way! Love the option of
                                music or no music during speech practice! I love
                                that therapists can quickly send email links at
                                the end of sessions to parents to know exactly
                                what to work on! And to know that home practice
                                can be simple and not take a lot of time or
                                effort! So excited about this new tool!
                                <div className="ml-5 mt-2">-Clinician user</div>
                            </div>
                        </SwiperSlide>
                    </Swiper>

                    <div>
                        <button className="swiper-plugin-navigation-nextEl">
                            <img
                                src={rightCaretImg}
                                alt=""
                                style={{
                                    width: 100,
                                }}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
