import apolloClient from "../../../config/apolloClient";
import { CreateGameLogGql, UpdateGameLogGql } from "./gameLog.gql";
import { CreateGameLog, GameLog, UpdateGameLog } from "./interfaces/gameLog.interface";

export const createGameLog = async (
    values: CreateGameLog
) => {
    const { data, errors } = await apolloClient.mutate<{
        createGameLog: GameLog;
    }>({
        mutation: CreateGameLogGql,
        variables: { input: values },
        fetchPolicy: "network-only",
    });

    if (errors) {
        throw errors;
    }

    return { response: data?.createGameLog };
};

export const updateGameLog = async (values: UpdateGameLog) => {
    const { data, errors } = await apolloClient.mutate<{
        updateGameLog: GameLog;
    }>({
        mutation: UpdateGameLogGql,
        variables: { input: values },
        fetchPolicy: "network-only",
    });

    if (errors) {
        throw errors;
    }

    return { response: data?.updateGameLog };
};
