interface IProps {
    type: "sound" | "word" | "level";
    onClick: () => void;
}

const TutorialButton = (props: IProps) => {
    const { onClick, type } = props;

    const returnName = () => {
        if (type === "word") return "Word Position";
        if (type === "sound") return "Sound";
        if (type === "level") return "Level";
    };

    const renderPlayIcon = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="currentColor"
            className="w-7 h-7"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
            />
        </svg>
    );

    return (
        <div className="flex justify-center">
            <div
                className="flex items-center gap-2 cursor-pointer w-max bg-aha-light-blue border-black rounded-full py-1 px-3"
                onClick={onClick}
            >
                {renderPlayIcon()}

                <div className="text-sm">
                    60 sec{" "}
                    <p className="font-century-gothic-bold inline">
                        {returnName()}
                    </p>{" "}
                    tutorial
                </div>
            </div>
        </div>
    );
};

export default TutorialButton;
