import React, { useState } from "react";
import closeImage from "../../../../assets/images/bake-a-cake/close.png";
import Modal from "react-modal";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { updateGameLog } from "../../../shared/services/analytics/gameLog.service";

Modal.defaultStyles.overlay.backgroundColor = "#00000060";

const customStyles = {
    content: {
        top: "30%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -30%)",
        width: 500,
        minHeight: 500,
        padding: "1rem",
    },
};

const GameFeedbackModal = ({
    showModal,
    onClose,
    gameLogId,
}: any) => {

    const [isLoading, setLoading] = useState(false);

    return (
        <Modal isOpen={showModal} style={customStyles} ariaHideApp={false}>
            <div className="flex justify-end">
                <img
                    src={closeImage}
                    style={{ width: '20px'}}
                    alt="Close Modal"
                    className="w-10 cursor-pointer"
                    onClick={() => {
                        onClose();
                    }}
                />
            </div>

            <h2 className="pb-4 font-century-gothic-bold">Send a message to your clinician (optional)</h2>

            <Formik
                    initialValues={{
                        message: "",
                    }}
                    onSubmit={async (values) => {
                        setLoading(true);
                        try {
                            await updateGameLog({
                                id: gameLogId,
                                message: values.message,
                            });
                            toast.success("Message sent successfully!");
                            onClose();
                        } catch (err) {
                            toast.error(err as string);
                        }
                        finally {
                            setLoading(false);
                        }
                    }}
                >
                    {() => (
                        <Form className={"space-y-6"}>
                            <div>
                                <Field
                                    name="message"
                                    placeholder="Practice went well today. We're making progress!"
                                    type="text"
                                    component="textarea"
                                    rows={10}
                                    className={
                                        "text-gray-700 border-2 border-gray-200 focus:outline-none w-full p-2 placeholder-gray-500 placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                            </div>

                            <div className={"flex justify-between py-6"}>
                                <button
                                    onClick={() => onClose()}
                                    className={
                                        "border border-aha-green  text-aha-green  py-2 px-14 font-bold transition duration-200 ease-in-out century-gothic-regular text-xl tracking-wider"
                                    }
                                >
                                    Skip
                                </button>
                                <button
                                    type="submit"
                                    className={
                                        "border border-aha-orange bg-aha-orange text-white py-2 px-14 font-bold transition duration-200 ease-in-out century-gothic-regular text-xl tracking-wider"
                                    }
                                >
                                    {isLoading ? 'Sending...' : 'Send Message'}                                    
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
        </Modal>
    );
};

export default GameFeedbackModal;
