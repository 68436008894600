import { ApolloProvider } from "@apollo/client";
import apolloClient from "./config/apolloClient";
import Root from "./containers/Root";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "./shared/context/authContext/AuthProvider";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AnalyticsProvider from "./containers/analytics/Analytics";
import { PrimeReactProvider } from "primereact/api";

export const logoutChannel = new BroadcastChannel("logout");

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE));

function App() {    
    return (
        <PrimeReactProvider>
            <ApolloProvider client={apolloClient}>
                <Elements stripe={stripePromise}>
                    <AuthProvider>
                        <AnalyticsProvider />
                        <Root />
                    </AuthProvider>
                    <ToastContainer
                        autoClose={5000}
                        hideProgressBar
                        pauseOnHover
                    />
                </Elements>
            </ApolloProvider>
        </PrimeReactProvider>
    );
}

export default App;
