import React, { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/authContext/AuthContext";

interface IProps {
    open: boolean;
    onClose: () => void;
}

const SideBar: FC<IProps> = (props) => {
    const { open, onClose } = props;
    const { state } = useContext(AuthContext);
    const [show, setShow] = useState(false);

    useEffect(() => {}, []);

    const onBackDrop = () => {
        onClose();
    };

    const onCloseSide = () => {
        onClose();
    };

    useEffect(() => {
        setShow(open);
    }, [open]);

    return (
        <>
            <div
                className={`navbar-menu relative z-50 ${show ? "" : "hidden"}`}
            >
                <div
                    onClick={onBackDrop}
                    className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"
                ></div>
                <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
                    <div className="flex items-center mb-8">
                        <div className="mr-auto"></div>
                        <button className="navbar-close" onClick={onCloseSide}>
                            <svg
                                className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <div>
                        <ul>
                            <Link
                                onClick={onClose}
                                className={
                                    "block border mb-3 rounded bg-white text-aha-green py-2 px-14 font-bold  hover:text-white hover:bg-aha-green transition duration-200 ease-in-out font-blanch-regular text-4xl tracking-wider whitespace-nowrap"
                                }
                                to={"/our-story"}
                            >
                                Our Story
                            </Link>

                            <Link
                                onClick={onClose}
                                className={
                                    "block border mb-3 rounded bg-white text-aha-green py-2 px-14 font-bold  hover:text-white hover:bg-aha-green transition duration-200 ease-in-out font-blanch-regular text-4xl tracking-wider whitespace-nowrap"
                                }
                                to="/terms-and-conditions"
                            >
                                Terms & Conditions
                            </Link>

                            <Link
                                onClick={onClose}
                                className={
                                    "block border mb-3 rounded bg-white text-aha-green py-2 px-14 font-bold  hover:text-white hover:bg-aha-green transition duration-200 ease-in-out font-blanch-regular text-4xl tracking-wider whitespace-nowrap"
                                }
                                to="/privacy-policy"
                            >
                                Privacy Policy
                            </Link>

                            <Link
                                onClick={onClose}
                                className={
                                    "block border mb-3 rounded bg-white text-aha-green py-2 px-14 font-bold  hover:text-white hover:bg-aha-green transition duration-200 ease-in-out font-blanch-regular text-4xl tracking-wider whitespace-nowrap"
                                }
                                to="/privacy-policy?contactUs=true"
                            >
                                Contact Us
                            </Link>

                            <Link
                                onClick={onClose}
                                className={
                                    "block border mb-3 rounded bg-white text-aha-green py-2 px-14 font-bold  hover:text-white hover:bg-aha-green transition duration-200 ease-in-out font-blanch-regular text-4xl tracking-wider whitespace-nowrap"
                                }
                                to="/blog"
                            >
                                Blogs
                            </Link>

                            {state.token && (
                                <Link
                                    onClick={onClose}
                                    className={
                                        "block border mb-3 rounded bg-white text-aha-green py-2 px-14 font-bold  hover:text-white hover:bg-aha-green transition duration-200 ease-in-out font-blanch-regular text-4xl tracking-wider whitespace-nowrap"
                                    }
                                    to="/app/my-account"
                                >
                                    My Account
                                </Link>
                            )}
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default SideBar;
