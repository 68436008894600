import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from "react-router-dom";
import LetsPlay from "../../containers/letsPlay";
import { useContext, useEffect } from "react";
import AuthContext from "../context/authContext/AuthContext";
import Game from "../../containers/letsPlay/Game";
import MyAccount from "../../containers/my-account";
import { STORAGE_KEYS } from "./Constants";
// import { useIntercom } from "react-use-intercom";
import GameFeedbackComponent from "../../containers/letsPlay/components/gameFeedbackComponent";
import AdminPortalWrapper from "../components/AdminPortalWrapper";

const PrivateRoute = () => {
    const { path } = useRouteMatch();
    const location = useLocation();

    const history = useHistory();

    // const { boot: bootIntercom } = useIntercom();

    const { state: authState } = useContext(AuthContext);

    useEffect(() => {
        if (!authState.isLoading && !authState.token && !authState.user) {
            if(!authState.avoidGameSetting){
                localStorage.setItem(STORAGE_KEYS.GAME_SETTINGS, location.search);
            }
            history.push("/login");
        }else{
            // bootIntercom({
            //     userId: authState?.user?.id || '',
            //     email: authState?.user?.email || '',
            //     name: `${authState?.user?.firstname || ''} ${authState?.user?.lastname || ''}`,
            //     customAttributes: { group_role: authState?.user?.group_role }
            // });
        }
    }, [authState]);    

    return (
        <div>
            <Switch>
                <Route exact path={`${path}/game`} component={Game} />
                <Route exact path={`${path}/game-feedback`} component={GameFeedbackComponent} />

                <Route exact path={`${path}/lets-play`} component={LetsPlay} />

                <Route exact path={`${path}/admin-portal`} component={AdminPortalWrapper} />

                <Route
                    exact
                    path={`${path}/my-account`}
                    component={MyAccount}
                />

                <Redirect to={"/not-found"} />
            </Switch>
        </div>
    );
};

export default PrivateRoute;
