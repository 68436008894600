import React from "react";

const ParentProducts = ({ onSelect, showSelectButtons = true }: any) => {
    return (
        <div className="flex-1">
            <div className="text-center text-2xl text-aha-orange mb-4">
                Start Today!
            </div>
            <div className="md:flex space-y-6 md:space-y-0 gap-6">
                <div className="bg-white text-center md:w-2/4">
                    <div className="p-4">
                        <div className="mb-10">
                            <div className="text-aha-orange font-blanch-bold tracking-widest text-5xl">
                                Monthly
                            </div>
                            <div className="tracking-wider">subscription</div>
                        </div>
                        <div className="text-4xl mb-10">$11.99/mo</div>
                    </div>
                    {showSelectButtons && (
                        <button
                            className="w-full block bg-aha-orange py-4 text-white font-blanch-regular tracking-wider text-5xl"
                            onClick={() => {
                                onSelect(1199);
                            }}
                        >
                            SELECT
                        </button>
                    )}
                </div>
                <div className="bg-white text-center md:w-2/4">
                    <div className="p-4">
                        <div className="mb-10">
                            <div className="text-aha-orange font-blanch-bold tracking-widest text-5xl">
                                Yearly
                            </div>
                            <div className="tracking-wider">subscription</div>
                        </div>
                        <div className="text-4xl mb-10">$96/annually</div>
                    </div>
                    {showSelectButtons && (
                        <button
                            className="w-full block bg-aha-orange py-4 text-white font-blanch-regular tracking-wider text-5xl"
                            onClick={() => {
                                onSelect(9600);
                            }}
                        >
                            SELECT
                        </button>
                    )}
                </div>
            </div>

            <div className="text-center py-10 space-y-6 text-aha-black">
                <div className="text-2xl uppercase font-century-gothic-bold tracking-wider">
                    purchase subscription
                </div>
                <div>
                    Select a subscription offer to
                    <br /> unlock{" "}
                    <span className="font-century-gothic-bold">
                        FULL ACCESS
                    </span>{" "}
                    to
                    <br /> At Home Articulation
                </div>
            </div>
        </div>
    );
};

export default ParentProducts;
