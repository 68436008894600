import { gql } from "@apollo/client";

export const MessageFragment = gql`
    fragment Message on Message {
        id
        message
    }
`;

export const GetMessages = gql`
    query GetMessages {
        GetMessages {
            ...Message
        }
    }
    ${MessageFragment}
`;

export const ReadMessages = gql`
    mutation ReadMessages($input: ReadMessageInput!) {
        ReadMessages(input: $input) {
            message
        }
    }
`;
