import React from "react";
import Modal from "react-modal";
import { XIcon } from "@heroicons/react/solid";
import popUpImage from "../../../assets/images/popup-image.png"
import wavesImage from "../../../assets/images/login-modal-waves.png"
import { COLORS } from "../../shared/utils/colors";
import { IModalOption } from "../interfaces/modal-option.interface";

const customStyles = {
    content: {
        top: "30%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -30%)",
        width: 450,
        minHeight: 450,
        padding: 0,
        zIndex: 100,
        border: "1px solid",
        borderColor: COLORS.aha_dark_blue,
        backgroundColor: COLORS.aha_light_blue_200,
    },
}

const ModalAfterLogin = ({ showModal, onClose }: IModalOption) => {
    return (
        <Modal
            isOpen={showModal}
            style={customStyles}
            ariaHideApp={false}
        >
            <header className="h-14 bg-aha-dark-blue items-center flex justify-end shadow-md border-black border-b border-solid">
                <div className="mr-3">
                    <button onClick={() => onClose(false)}>
                        <XIcon className={"w-10 text-white"} />
                    </button>
                </div>
            </header>
            <section className="pt-4 pb-4 items-center flex justify-center">
                <img
                    src={popUpImage}
                    alt="Close Modal"
                    className="aspect-h-48"
                />
            </section>
            <h1 className="text-aha-dark-blue text-2xl font-bold text-center">
                New version is now available
            </h1>
            <article className="container mx-auto pt-4 relative">
                <p className="text-aha-dark-blue text-center font-normal">We hear you! Say hello to our new and improved game selection menu where you can enjoy a much more simplified and modern view. 
                    We've also taken the time to get rid of some of those pesky bugs you have been seeing. </p>
                <p className="mt-4 text-aha-dark-blue font-normal text-base text-center">
                    <span className="font-bold">Note:</span> If you are using our mobile app, be sure to click update to get the new and improved experience on your phone or tablet.
                </p>
                <div className="pt-2 pb-4">
                    <img
                        src={wavesImage}
                        alt="waves Modal"
                        className="w-full"
                    />
                </div>
            </article>
        </Modal>
    );
};

export default ModalAfterLogin;
