import React from "react";
import cliniciansBanner from "./../../../assets/images/clinicians-banner.png";

const ClinicianBanner = ({
    showPricingBtn = false,
    onPricingBtnClick = null,
}: any) => {
    return (
        <div className="p-6 bg-aha-black text-white space-y-4 flex-1 min-h-full h-auto uppercase">
            <div className="md:px-6">
                <img src={cliniciansBanner} />
            </div>
            {/* <div className="text-center font-century-gothic-bold text-xl tracking-wider">
                Why Premium?
            </div> */}
            <ul className="space-y-2 pl-4 text-aha-green lg:text-lg xl:text-xl">
                <li>
                    <span className="text-white">
                        send individualized home practice directly to families
                        in less than 10 seconds
                    </span>
                </li>
                <li>
                    <span className="text-white">
                        a lifetime subscription for clinicians to access
                        materials year after year
                    </span>
                </li>
                <li>
                    <span className="text-white">
                        manage your articulation student caseload with a single
                        comprehensible tool
                    </span>
                </li>
                <li>
                    <span className="text-white">
                        ever growing library of research-based clinician
                        resources
                    </span>
                </li>
                <li>
                    <span className="text-white">
                        invite your student's caregivers to the program with no
                        purchase necessary
                    </span>
                </li>
            </ul>
            {showPricingBtn ? (
                <div className="md:flex justify-end pt-4 text-center">
                    <button
                        className="bg-aha-green py-2 px-8 uppercase text-4xl font-blanch-bold tracking-widest"
                        onClick={() => {
                            if (onPricingBtnClick) {
                                onPricingBtnClick();
                            }
                        }}
                    >
                        i'm an slp!
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default ClinicianBanner;
