import React from "react";

const RegistrationSuccess = () => {
    return (
        <div
            className="text-center bg-white py-12 space-y-6"
            style={{
                marginBottom: 200,
            }}
        >
            <div className="text-aha-orange text-5xl font-century-gothic-bold tracking-wider">
                THANK YOU
            </div>
            <div className="space-y-4 text-aha-black text-lg">
                <div>for registering for At Home Articulation!</div>
                <div>In order to begin, please check your email.</div>
                <div>We've sent you a verification link.</div>
            </div>
        </div>
    );
};

export default RegistrationSuccess;
