export enum SubscriptionTierEnum {
    FREE = "FREE",
    MONTHLY = "MONTHLY",
    YEARLY = "YEARLY",
    CLINICIAN_SUBSCRIPTION = "CLINICIAN_SUBSCRIPTION",
    CLINICIAN_SUBSCRIPTION_ANNUAL = "CLINICIAN_SUBSCRIPTION_ANNUAL",
    FOR_EVER = "FOR_EVER",
    ORGANIZATION_SUBSCRIPTION = "ORGANIZATION_SUBSCRIPTION",
    FREE_TRIAL = "FREE_TRIAL",
}
