import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../shared/context/authContext/AuthContext";
import {
    getUserPaymentInfo,
    getUserSubscriptionInfo,
} from "../../shared/services/user/user.service";
import { ISubscriptionInfo } from "../../shared/interfaces/subscription-info";
import SubscriptionModal from "../letsPlay/SubscriptionModal";
import { SubscriptionTierEnum } from "../../shared/enums/SubscriptionTier";
import { AppRoles } from "../../shared/enums/role.enum";
import { IPaymentInfo } from "../../shared/interfaces/payment-info.interface";
import SubscriptionInfo from "./components/SubscriptionInfo";
import CardInfo from "./components/CardInfo";
import PaymentInfo from "./components/PaymentInfo";

const MyAccount = () => {
    const { state } = useContext(AuthContext);
    const [subscription, setSubscription] = useState<ISubscriptionInfo>();
    const [payment, setPayment] = useState<IPaymentInfo>();
    const [showSubsModal, setShowSubsModal] = useState(false);
    const [activeSubscription, setActiveSubscription] = useState(false);
    const [loading, setLoading] = useState(true);
    const user = state.user;

    const getInfo = async () => {
        setLoading(true);

        if (user?.role === AppRoles.PARENT) {
            const { response, errors } = await getUserSubscriptionInfo();

            if (response) {
                setSubscription(response);
            }
        } else if (user?.role === AppRoles.CLINICIAN) {
            const { response, errors } = await getUserPaymentInfo();

            if (errors) {
                const errorCode = errors[0].extensions?.code;
                if (errorCode === "404") {
                    const { response, errors } =
                        await getUserSubscriptionInfo();

                    if (response) {
                        setSubscription(response);
                    }
                }
            } else if (response) {
                setPayment(response);
            }
        }

        setLoading(false);
    };

    const getDate = (date: string | undefined): string | void => {
        if (date) {
            const d = new Date(date);
            const day = d.getUTCDate();
            const month = d.getUTCMonth() + 1;
            const year = d.getUTCFullYear();

            return `${month}/${day}/${year}`;
        }
    };

    const handleCreateSubs = () => {
        setShowSubsModal(true);
    };

    useEffect(() => {
        if (state && state.user) {
            getInfo();
        }
    }, [state]);

    useEffect(() => {
        if (subscription) {
            if (subscription.status === "active") {
                setActiveSubscription(true);
            }
        }
    }, [subscription]);

    const renderParentContent = () => {
        const userFreeTrial =
            user?.subscriptionTier === SubscriptionTierEnum.FREE_TRIAL;
        const b2bUser =
            user?.subscriptionTier ===
            SubscriptionTierEnum.ORGANIZATION_SUBSCRIPTION;

        if (b2bUser) {
            return (
                <div>
                    <p>
                        You have premium access to At Home Articulation through{" "}
                        {user.organization?.organizationName}
                    </p>
                </div>
            );
        }

        if (subscription && activeSubscription) {
            return (
                <div className="flex gap-10">
                    <SubscriptionInfo
                        subscription={subscription}
                        onUpdate={getInfo}
                    />
                    <CardInfo
                        type="subscription"
                        cardNumber={subscription.cardNumber}
                        cardBrand={subscription.cardBrand}
                        onUpdate={getInfo}
                    />
                </div>
            );
        } else {
            return (
                <>
                    {userFreeTrial && (
                        <>
                            <p>
                                <strong>
                                    You have an active free trial until{" "}
                                    {getDate(user.freeTrialEndsAt)}
                                </strong>
                            </p>
                        </>
                    )}

                    {!b2bUser && (
                        <>
                            <button
                                onClick={handleCreateSubs}
                                className={
                                    "mt-10 border border-aha-green bg-aha-green text-white py-2 px-14 font-bold  hover:text-aha-green hover:bg-white transition duration-200 ease-in-out font-blanch-regular text-2xl tracking-wider whitespace-nowrap"
                                }
                            >
                                subscribe now
                            </button>
                            <SubscriptionModal
                                skipRoleDescription={true}
                                showModal={showSubsModal}
                                onClose={(value) => {
                                    setShowSubsModal(false);
                                }}
                            />
                        </>
                    )}
                </>
            );
        }
    };

    const renderClinicianContent = () => {
        const freeTrialUser =
            user?.subscriptionTier === SubscriptionTierEnum.FREE_TRIAL;

        const b2bUser =
            user?.subscriptionTier ===
            SubscriptionTierEnum.ORGANIZATION_SUBSCRIPTION;

        if (b2bUser) {
            return (
                <div>
                    <p>
                        You have premium access to At Home Articulation through{" "}
                        {user.organization?.organizationName}
                    </p>
                </div>
            );
        }

        if (payment || (subscription && activeSubscription)) {
            if (payment) {
                return (
                    <>
                        <div className="flex gap-10">
                            <PaymentInfo payment={payment} onUpdate={getInfo} />
                            <CardInfo
                                type="one-payment"
                                cardNumber={payment.cardNumber}
                                cardBrand={payment.cardBrand}
                                onUpdate={getInfo}
                            />
                        </div>
                    </>
                );
            } else if (subscription) {
                return (
                    <div className="flex gap-10">
                        <SubscriptionInfo
                            subscription={subscription}
                            onUpdate={getInfo}
                        />
                        <CardInfo
                            type="subscription"
                            cardNumber={subscription.cardNumber}
                            cardBrand={subscription.cardBrand}
                            onUpdate={getInfo}
                        />
                    </div>
                );
            }
        } else {
            return (
                <>
                    {freeTrialUser && (
                        <p>
                            <strong>
                                You have an active free trial until{" "}
                                {getDate(user.freeTrialEndsAt)}
                            </strong>
                        </p>
                    )}

                    {!b2bUser && (
                        <>
                            <button
                                onClick={handleCreateSubs}
                                className={
                                    "mt-10 border border-aha-green bg-aha-green text-white py-2 px-14 font-bold  hover:text-aha-green hover:bg-white transition duration-200 ease-in-out font-blanch-regular text-2xl tracking-wider whitespace-nowrap"
                                }
                            >
                                subscribe now
                            </button>
                            <SubscriptionModal
                                showModal={showSubsModal}
                                onClose={(value) => {
                                    setShowSubsModal(false);
                                }}
                            />
                        </>
                    )}
                </>
            );
        }

        return <></>;
    };

    return (
        <div className={"container min-h-screen py-10 w-full"}>
            <h1 className="text-4xl mb-10">My Account</h1>

            {loading ? (
                <p>Loading...</p>
            ) : user?.role === AppRoles.PARENT ? (
                renderParentContent()
            ) : (
                renderClinicianContent()
            )}
        </div>
    );
};

export default MyAccount;
