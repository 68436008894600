import React, { useState } from "react";
import banner1img from "./../../../assets/images/group-registration/group_landing_page_broken-05.png";
import speechPracticePreview from "./../../../assets/images/clinician-registration/clinician landing page_split-30.png";
import yellowDotted from "./../../../assets/images/clinician-registration/clinician landing page_split-32.png";

import whatsSentBanner from "./../../../assets/images/group-registration/group_landing_page_broken-17.png";
import mobilePreview from "./../../../assets/images/group-registration/group_landing_page_broken-18.png";

import Testimonials from "../parent-registration/components/Testimonials";
import banner8 from "./../../../assets/images/group-registration/group_landing_page_broken-01.png";
import sectionTwoBGImage from "./../../../assets/images/section-two-bg.png";

import NotifyMe from "../../shared/components/NotifyMe";
import { getHeightForNotifyMe } from "../../shared/utils/get-height";
import ContactUsModal from "./contactUsModal";

const REACT_APP_VIDEO_SEND_HOMEWORK =
    process.env[`REACT_APP_VIDEO_SEND_HOMEWORK`];

const REACT_APP_VIDEO_THERAPY_TOOL =
    process.env[`REACT_APP_VIDEO_THERAPY_TOOL`];

const videoHwUrl = `${REACT_APP_VIDEO_SEND_HOMEWORK}?autoplay=1&muted=1&loop=1&autopause=0`;
const videoTherapyUrl = `${REACT_APP_VIDEO_THERAPY_TOOL}?autoplay=1&muted=1&loop=1&autopause=0`;

const GroupRegistration = () => {

    const [showModal, setShowModal] = useState(false);

    const openPopUpText = () => (
        <p>
            <span className="font-bold mr-2">
                Click here
            </span>
            to send us a quick message
        </p>
    );

    return (
        <>
            <section className="bg-aha-black py-6 lg:py-0">
                <div className="lg:flex container">
                    <div className="bg-aha-black text-white lg:w-2/5 grid place-items-center">
                        <div className="space-y-4 w-4/5">
                            <div className="text-center text-2xl lg:text-3xl xl:text-5xl font-century-gothic-bold space-y-2">
                                <p>Your go-to</p>
                                <p>speech</p>
                                <p>carryover tool</p>
                            </div>
                            <div className="text-center text-xl xl:text-2xl">
                                <p>Outcomes for your clinicians</p>
                                <p>and the parents they serve</p>
                            </div>
                            <div className="bg-aha-orange py-4 px-5 text-center text-md xl:text-xl space-y-1 tracking-wider cursor-pointer" onClick={() => setShowModal(true)}>
                                <p className="font-bold">
                                    Interested in group pricing?
                                </p>
                                {
                                    openPopUpText()
                                }
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-3/5">
                        <img
                            src={banner1img}
                            alt="Banner 1"
                            className="w-full object-contain pt-6 lg:pt-0"
                        />
                    </div>
                </div>
            </section>
            <section className="text-aha-black text-center pt-4">
                <div className="pt-6 pb-12 container">
                    <div className="text-4xl font-bold space-y-2">
                        <div>Our goal is to decrease clinician</div>
                        <div>turnover by</div>
                        <div className="text-aha-green text-5xl ">
                            lightening their load
                        </div>
                    </div>
                </div>
                <div className="bg-aha-light-blue pb-12 pt-10 text-lg">
                    <div className="container items-center space-y-20 gap-20 lg:flex lg:space-y-0">
                        <div className="lg:w-2/4 space-y-6">
                            <div className="sm:flex items-center gap-6">
                                <div className="m-auto w-3/4 text-md xl:text-2xl">
                                    On average it takes an organized clinician
                                    over one hour each week to send speech
                                    homework
                                </div>
                                <div className="m-auto mt-5 sm:mt-0 w-20 xl:w-28">
                                    <div className="rounded-full bg-aha-orange text-white aspect-w-1 aspect-h-1 ">
                                        <div className="grid place-items-center">
                                            <div className="text-center text-sm">
                                                <div className="font-boldtext-sm lg:text-xl xl:text-5xl">
                                                    1
                                                </div>
                                                <p className="text-xs">Hour</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sm:flex items-center gap-6">
                                <div className="m-auto w-3/4 text-md xl:text-2xl">
                                    At Home Articulation reduces that number to
                                </div>
                                <div className="m-auto mt-5 sm:mt-0 w-20 xl:w-28">
                                    <div className="rounded-full bg-aha-orange text-white aspect-w-1 aspect-h-1 ">
                                        <div className="grid place-items-center">
                                            <div className="text-center">
                                                <div className="font-bold text-sm lg:text-xl xl:text-5xl">
                                                    5
                                                </div>
                                                <p className="text-xs">
                                                    minutes
                                                    <br />
                                                    per week
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sm:flex items-center gap-6">
                                <div className="m-auto w-3/4 text-md xl:text-2xl">
                                    What could your clinicians do with an extra
                                </div>
                                <div className="m-auto mt-5 sm:mt-0 w-20 xl:w-28">
                                    <div className="rounded-full bg-aha-orange text-white aspect-w-1 aspect-h-1 ">
                                        <div className="grid place-items-center">
                                            <div className="text-center text-sm">
                                                <div className="font-bold text-sm lg:text-xl xl:text-5xl">
                                                    55
                                                </div>
                                                <p className="text-xs">
                                                    minutes
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-3/5 m-auto">
                            <div className="aspect-w-16 aspect-h-9 bg-aha-black border-12 border-aha-black ">
                                <iframe
                                    className="m-auto"
                                    id="SendHWVideo"
                                    src={videoHwUrl}
                                    allow="autoplay"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="p-12">
                <div className="container space-y-6">
                    <div className="">
                        <p className="text-center text-xl lg:text-3xl text-aha-black mb-3">
                            What's being sent?
                        </p>
                        <p className="text-center text-2xl  lg:text-4xl text-aha-green font-bold">
                            Speech practice kids actually want to do
                        </p>
                    </div>
                    <div>
                        <img src={speechPracticePreview} alt="" />
                    </div>
                    <div>
                        <img src={yellowDotted} alt="" />
                    </div>
                    <div className="text-center text-gray-500">
                        <div className="text-xl xl:text-2xl 2xl:text-3xl mb-4">
                            "The outcomes of gamification [are].. increased
                            motivation, engagement, and enjoyment.
                        </div>
                        <div className="italic text-xl xl:text-2xl">
                            -E-Learning and Digital Media
                        </div>
                    </div>
                </div>
            </section>

            <section className="p-12 bg-aha-light-blue">
                <div className="container space-y-6">
                    <div className="">
                        <img
                            src={whatsSentBanner}
                            alt=""
                            className="md:w-3/5 mx-auto"
                        />
                    </div>
                    <div>
                        <img src={mobilePreview} alt="" />
                    </div>
                </div>
            </section>

            <Testimonials />

            <section className="p-12 bg-aha-black">
                <div className="md:w-3/5 mx-auto">
                    <div>
                        <p className="text-center font-century-gothic-regular font-bold text-xl lg:text-4xl xl:text-5xl text-white mb-3">
                            A great therapy tool for{" "}
                            <span className="text-aha-orange">new</span>
                        </p>
                        <p className="text-center font-century-gothic-regular font-bold text-xl lg:text-4xl xl:text-5xl text-white">
                            and{" "}
                            <span className="text-aha-orange">seasoned</span>{" "}
                            clinicians
                        </p>
                    </div>

                    <div className="flex justify-center mt-8">
                        <div className="md:w-3/5 w-full">
                            <div className="border-20 border-aha-light-blue aspect-w-16 aspect-h-9 bg-transparent">
                                <iframe
                                    id="SendHWVideo2"
                                    width="560"
                                    height="315"
                                    src={videoTherapyUrl}
                                    allow="autoplay"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-aha-light-blue-500">
                <div className="md:flex ">
                    <div className="md:w-3/6">
                        <img
                            src={banner8}
                            alt="Banner 1"
                            className="w-full object-contain"
                        />
                    </div>
                    <div className="py-6 md:py-0 bg-aha-light-blue-500  md:w-3/4 grid place-items-center ">
                        <div className="space-y-12 md:w-3/5">
                            <div className="text-center text-aha-black text-2xl lg:text-4xl xl:text-5xl 2xl:text-6xl  tracking-widest space-y-2">
                                <div>Interested in</div>
                                <div>group pricing?</div>
                            </div>

                            <div className="bg-aha-orange py-8 px-5 text-center 
                            text-md lg:text-xl 
                            xl:text-2xl 2xl:text-3xl space-y-1 tracking-wider text-white 
                            font-bold cursor-pointer" onClick={() => setShowModal(true)}>
                                {
                                    openPopUpText()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-12 bg-aha-light-blue ">
                <NotifyMe invertedTheme />

                <div
                    style={{
                        backgroundImage: `url(${sectionTwoBGImage})`,
                        minHeight: getHeightForNotifyMe(),
                    }}
                    className={"bg-cover bg-no-repeat bg-bottom"}
                ></div>
            </section>

            <ContactUsModal 
                showModal={showModal}
                onClose={(ev: any) => {
                    setShowModal(false);
                }}
            />
        </>
    );
};

export default GroupRegistration;
