import apolloClient from "../../../config/apolloClient";
import { IAnalytics } from "../../interfaces/analytics.interface";
import { IIncrementalAnalytic } from "../../interfaces/incremental-analytic.interface";
import { CreateIncrementalAnalytic, UpdateAnalytics } from "./analytics.gql";
import {
    IIncrementalAnalyticsInput,
    IUpdateAnalyticsInput,
} from "./interfaces/incremental-analytics.interface";

export const createIncrementalAnalytic = async (
    values: IIncrementalAnalyticsInput
) => {
    const { data, errors } = await apolloClient.mutate<{
        CreateIncrementalAnalytic: IIncrementalAnalytic;
    }>({
        mutation: CreateIncrementalAnalytic,
        variables: { input: values },
        fetchPolicy: "network-only",
    });

    if (errors) {
        throw errors;
    }

    return { response: data?.CreateIncrementalAnalytic };
};

export const updateAnalytics = async (values: IUpdateAnalyticsInput) => {
    const { data, errors } = await apolloClient.mutate<{
        UpdateAnalytics: IAnalytics;
    }>({
        mutation: UpdateAnalytics,
        variables: { input: values },
        fetchPolicy: "network-only",
    });

    if (errors) {
        throw errors;
    }

    return { response: data?.UpdateAnalytics };
};
