import { gql } from "@apollo/client";

export const UpdateGameLogGql = gql`
    mutation updateGameLog($input: UpdateGameLogInput!) {
        updateGameLog(input: $input) {
            id
        }
    }
`;

export const CreateGameLogGql = gql`
    mutation createGameLog($input: CreateGameLogInput!) {
        createGameLog(input: $input) {
            id
        }
    }
`;
