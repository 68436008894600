import { logoutChannel } from "../../App";

function useLogoutAllTabs() {
    const postMessage = (message: string)  => {
        logoutChannel.postMessage(message);
    }
    
    const logoutAllTabs = (executeCallback: Function) => {
        logoutChannel.onmessage = () => {
            executeCallback();
            logoutChannel.close();
        };
    };

    return {
        logoutAllTabs,
        postMessage,
    };
}

export default useLogoutAllTabs;
