import React, { FC } from "react";
import notifyMeButton from "./../../../assets/images/yes-please.png";

interface IProps {
    invisible?: boolean;
    invertedTheme?: boolean;
}

const NotifyMe: FC<IProps> = (props) => {
    const { invisible, invertedTheme } = props;
    return (
        <div id="notifyMe">
            <div
                className={`${
                    invisible ? "bg-aha-green hidden py-0" : "bg-aha-green"
                }
                
                
                ${
                    invertedTheme
                        ? "bg-aha-light-blue text-aha-black"
                        : "bg-aha-green text-white"
                }
                `}
            >
                <div className="container py-10">
                    <div className=" mx-auto">
                        <div
                            className={`text-center  text-2xl uppercase font-century-gothic-bold tracking-wider mb-4`}
                        >
                            <div>
                                stay in the loop for the latest at home
                                articulation updates.
                            </div>
                            <div>
                                new features and research-based tips and tricks!
                            </div>
                        </div>

                        <div id="mc_embed_signup">
                            <form
                                action="https://athomearticulation.us12.list-manage.com/subscribe/post?u=be0357225ebc37b941c5b1f14&amp;id=6f65a938a9"
                                method="post"
                                id="mc-embedded-subscribe-form"
                                name="mc-embedded-subscribe-form"
                                className="validate space-y-4"
                                target="_blank"
                                noValidate
                                onSubmit={(ev: any) => {
                                    setTimeout(() => {
                                        ev?.target?.reset();
                                    }, 200);
                                }}
                            >
                                <div className="space-y-1">
                                    <div className="">Name</div>
                                    <div>
                                        <input
                                            type="text"
                                            className="w-full py-1 px-2 required"
                                            name="NAME"
                                            id="mce-NAME"
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className="">Email Address</div>
                                    <div>
                                        <input
                                            type="email"
                                            className="w-full py-1 px-2 required email"
                                            name="EMAIL"
                                            id="mce-EMAIL"
                                        />
                                    </div>
                                </div>
                                <div className="space-x-6 ">
                                    <label className="cursor-pointer">
                                        Parent{" "}
                                        <input
                                            type="radio"
                                            value="Parent"
                                            name="MMERGE2"
                                            id="mce-MMERGE2-0"
                                        />
                                    </label>
                                    <label className="cursor-pointer">
                                        Clinician{" "}
                                        <input
                                            type="radio"
                                            value="Clinician"
                                            name="MMERGE2"
                                            id="mce-MMERGE2-1"
                                        />
                                    </label>
                                </div>
                                <div id="mce-responses" className="clear foot">
                                    <div
                                        className="response"
                                        id="mce-error-response"
                                        style={{ display: "none" }}
                                    ></div>
                                    <div
                                        className="response"
                                        id="mce-success-response"
                                        style={{ display: "none" }}
                                    ></div>
                                </div>
                                <div className="flex">
                                    <button
                                        style={{ width: 250 }}
                                        className={
                                            "opacity-90 hover:opacity-100 transition-all button"
                                        }
                                        type="submit"
                                        value="Subscribe"
                                        name="subscribe"
                                        id="mc-embedded-subscribe"
                                    >
                                        <img
                                            src={notifyMeButton}
                                            alt="Notify Me"
                                        />
                                    </button>
                                </div>

                                <div
                                    style={{
                                        position: "absolute",
                                        left: -5000,
                                    }}
                                    aria-hidden="true"
                                >
                                    <input
                                        type="text"
                                        name="b_be0357225ebc37b941c5b1f14_6f65a938a9"
                                        tabIndex={-1}
                                        value=""
                                        onChange={() => {}}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotifyMe;
