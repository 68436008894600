import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
    loginRequest,
    registerRequest,
} from "../../shared/services/auth/auth.service";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AUTH_ROUTES } from "../../shared/routes/Constants";
import banner from "../../../assets/images/clinician-registration-banner.png";
import { toast } from "react-toastify";
import { withAshaSchema, withoutAshaSchema } from "./utils/schemas";

const REACT_APP_PORTAL_URL = process.env[`REACT_APP_PORTAL_URL`];

const ClinicianRegister = () => {
    const location = useLocation();
    const [freeUser, setFreeUser] = useState(false);
    const [showAsha, setShowAsha] = useState(false);
    const [terms, setTerms] = useState(false);

    const [signUpSchema, setSignUpSchema] = useState(withoutAshaSchema);

    const handleLogin = async (values: { email: string; password: string }) => {
        const { response, errors } = await loginRequest({
            ...values,
            email: values.email.toLowerCase(),
        });

        if (errors) {
            toast.error({
                message:
                    "There was an error logging you in automatically, please try it yourself.",
            });
        } else if (response) {
            window.location.href = `${REACT_APP_PORTAL_URL}/auth/login-from-web?token=${response.accessToken}`;
        }
    };

    useEffect(() => {
        if (showAsha) {
            setSignUpSchema(withAshaSchema);
        } else {
            setSignUpSchema(withoutAshaSchema);
        }
    }, [showAsha]);

    useEffect(() => {
        if (location) {
            if (location.pathname === AUTH_ROUTES.FREE_REGISTER_CLINICIAN) {
                setFreeUser(true);
            } else {
                setFreeUser(false);
            }
        }
    }, [location]);

    const handleRadioButtons = (value: string) => {
        if (value === "ASHA Certified SLP") {
            setShowAsha(true);
        } else {
            setShowAsha(false);
        }
    };

    return (
        <div className={"pt-14 pb-12"}>
            <img
                className="mb-10 w-12/12 md:w-7/12 m-auto"
                src={banner}
                alt="clinician registration banner"
            />
            <div className="mb-10">
                <p className="text-gray-500 text-xl text-center">
                    Start you 2-week free trial today!
                </p>
                <p className="text-gray-500 text-l text-center">
                    No Credit Card information Required
                </p>
            </div>
            <div className={"md:w-2/5 m-auto"}>
                <Formik
                    initialValues={{
                        firstname: "",
                        lastname: "",
                        email: "",
                        password: "",
                        clinician: {
                            ashaNumber: "",
                        },
                    }}
                    validationSchema={signUpSchema}
                    onSubmit={async (values, { setFieldError }) => {
                        if (!terms) {
                            toast.error(
                                "You must accept the terms and conditions."
                            );
                            return;
                        }

                        const payload: any = {
                            ...values,
                            email: values.email.toLowerCase(),
                        };

                        delete payload.confirm_password;

                        if (!showAsha) {
                            payload.clinician = { ashaNumber: "" };
                        }

                        if (freeUser) {
                            payload["freeUser"] = true;
                        }

                        const { message, errors } = await registerRequest(
                            payload
                        );

                        if (errors) {
                            const errorMessage = errors
                                .map((x: any) => x.message)
                                .join("");

                            if (errorMessage === "Email already taken!") {
                                setFieldError(
                                    "email",
                                    "Email is already registered. Please sign in."
                                );
                            }
                        } else if (message) {
                            const values = {
                                email: payload.email,
                                password: payload.password,
                            };

                            handleLogin(values);
                        }
                    }}
                >
                    {({ errors, touched }) => (
                        <Form className={"space-y-6"}>
                            <div>
                                <Field
                                    name="firstname"
                                    autoComplete={"new-password"}
                                    placeholder="FIRST NAME"
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="firstname"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>
                            <div>
                                <Field
                                    name="lastname"
                                    autoComplete={"new-password"}
                                    placeholder="LAST NAME"
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="lastname"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>
                            <div>
                                <Field
                                    name="email"
                                    autoComplete={"new-password"}
                                    placeholder="EMAIL"
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="email"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>
                            <div>
                                <Field
                                    name="password"
                                    placeholder="PASSWORD"
                                    type="password"
                                    autoComplete={"new-password"}
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="password"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>

                            <div>
                                <Field
                                    name="confirm_password"
                                    placeholder="CONFIRM PASSWORD"
                                    type="password"
                                    autoComplete={"new-password"}
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="confirm_password"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>

                            <div>
                                <label>
                                    <span className="pl-2 text-aha-green">
                                        I am a(n)..
                                    </span>
                                </label>
                            </div>
                            <div>
                                <div className="list-none items-center w-full text-xs bg-white border border-gray-200 rounded-lg sm:flex">
                                    <div className="w-full">
                                        <div className="flex items-center pl-3 pr-3">
                                            <input
                                                id="horizontal-list-radio-license-1"
                                                type="radio"
                                                value="ASHA Certified SLP"
                                                name="picked"
                                                className=" h-4"
                                                onChange={(e) =>
                                                    handleRadioButtons(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <label
                                                htmlFor="horizontal-list-radio-license-1"
                                                className="w-full py-3 ml-2 text-xs text-aha-green"
                                            >
                                                ASHA Certified SLP
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <div className="flex items-center pl-3 pr-3">
                                            <input
                                                id="horizontal-list-radio-license-2"
                                                type="radio"
                                                value="SLT/SLPA"
                                                name="picked"
                                                className="w-4 h-4"
                                                onChange={(e) =>
                                                    handleRadioButtons(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <label
                                                htmlFor="horizontal-list-radio-license-2"
                                                className="w-full py-3 ml-2 text-xs text-aha-green"
                                            >
                                                SLT/SLPA
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <div className="flex items-center pl-3 pr-3">
                                            <input
                                                id="horizontal-list-radio-license-3"
                                                type="radio"
                                                value="Clinical Fellow"
                                                name="picked"
                                                className="w-4 h-4"
                                                onChange={(e) =>
                                                    handleRadioButtons(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <label
                                                htmlFor="horizontal-list-radio-license-3"
                                                className="w-full py-3 ml-2 text-xs text-aha-green"
                                            >
                                                Clinical Fellow
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <div className="flex items-center pl-3 pr-3">
                                            <input
                                                id="horizontal-list-radio-license-4"
                                                type="radio"
                                                value="Student"
                                                name="picked"
                                                className="w-4 h-4"
                                                onChange={(e) =>
                                                    handleRadioButtons(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <label
                                                htmlFor="horizontal-list-radio-license-4"
                                                className="w-full py-3 ml-2 text-xs text-aha-green"
                                            >
                                                Student
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {showAsha && (
                                <div>
                                    <Field
                                        name="clinician.ashaNumber"
                                        placeholder="ASHA #"
                                        className={
                                            "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                        }
                                    />
                                    <ErrorMessage
                                        name="clinician.ashaNumber"
                                        component={"div"}
                                        className="text-red-500 capitalize"
                                    />
                                </div>
                            )}

                            <div>
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="terms"
                                        className="w-4 h-4"
                                        onChange={(e) =>
                                            setTerms(e.target.checked)
                                        }
                                    />
                                    <label
                                        htmlFor="horizontal-list-radio-license-3"
                                        className="w-full py-3 ml-2 text-xs text-aha-green"
                                    >
                                        I agree to the{" "}
                                        <Link
                                            className="underline text-blue-400"
                                            to={"/terms-and-conditions"}
                                        >
                                            terms/conditions
                                        </Link>{" "}
                                        and{" "}
                                        <Link
                                            className="underline text-blue-400"
                                            to={"/privacy-policy"}
                                        >
                                            privacy policy
                                        </Link>
                                        .
                                    </label>
                                </div>
                            </div>

                            <div className={"text-center py-6"}>
                                <button
                                    className={
                                        "border border-aha-green bg-aha-green text-white py-2 px-14 font-bold  hover:text-aha-green hover:bg-white transition duration-200 ease-in-out font-blanch-regular text-4xl tracking-wider"
                                    }
                                >
                                    Start Your 2-week Free Trial!
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ClinicianRegister;
