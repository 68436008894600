import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import HomePage from "../../containers/home";
import { AUTH_ROUTES } from "./Constants";
import Login from "../../containers/auth/Login";
import AuthContext from "../context/authContext/AuthContext";
import ForgotPassword from "../../containers/auth/ForgotPassword";
import ChangePassword from "../../containers/auth/ChangePassword";
import NotifyMe from "../components/NotifyMe";
import ParentRegistration from "../../containers/parent-registration";
import ClinicianRegistration from "../../containers/clinician-registration";
import GroupRegistration from "../../containers/group-registration";
import RegistrationSuccess from "../../containers/auth/RegistrationSuccess";
import ParentRegister from "../../containers/auth/ParentRegister";
import ClinicianRegister from "../../containers/auth/ClinicianRegister";
import RedirectToApp from "../../containers/redirect/redirect-to-app";
import SystemCheckComponent from "../../containers/system-check";
import { useIntercom } from "react-use-intercom";
import HubspotChatWrapper from "../../containers/hubspot-chat-wrapper/chat-wrapper";

const PublicRoute = () => {
    const { state: authState } = useContext(AuthContext);

    const { hide } = useIntercom();

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (!authState.isLoading) {
            setIsLoggedIn(authState.token != null && authState.user != null);
        }
    }, [authState]);

    useEffect(() => {        
        if(!isLoggedIn){
          hide();
      }
    }, [isLoggedIn])
    

    return (
        <div className={""}>
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route
                    exact
                    path="/registration-success"
                    component={RegistrationSuccess}
                />
                <Route
                    exact
                    path="/parent-registration"
                    component={ParentRegistration}
                />
                <Route
                    exact
                    path="/clinician-registration"
                    component={ClinicianRegistration}
                />
                <Route
                    exact
                    path="/group-registration"
                    component={GroupRegistration}
                />
                <Route
                    exact
                    path={`/system-check`}
                    component={SystemCheckComponent}
                />

                {!isLoggedIn && (
                    <>
                        <Route
                            exact
                            path={`${AUTH_ROUTES.BASE}${AUTH_ROUTES.CHANGE_PASSWORD}/:token`}
                            component={ChangePassword}
                        />
                        <Route
                            exact
                            path={`${AUTH_ROUTES.LOGIN}`}
                            component={Login}
                        />
                        {/* Register */}
                        <Route
                            exact
                            path={`${AUTH_ROUTES.REGISTER}`}
                            component={ParentRegister}
                        />
                        <Route
                            exact
                            path={`${AUTH_ROUTES.CLINICIAN_REGISTER}`}
                            component={ClinicianRegister}
                        />
                        <Route
                            exact
                            path={`${AUTH_ROUTES.FREE_REGISTER}`}
                            component={ParentRegister}
                        />
                        <Route
                            exact
                            path={`${AUTH_ROUTES.FREE_REGISTER_CLINICIAN}`}
                            component={ClinicianRegister}
                        />
                        {/* Register */}
                        <Route
                            exact
                            path={`${AUTH_ROUTES.FORGOT_PASSWORD}`}
                            component={ForgotPassword}
                        />
                        <Route
                            exact
                            path={`/redirect-to-app`}
                            component={RedirectToApp}
                        />
                        <Route
                            exact
                            path={`/hubspot-wrapper`}
                            component={HubspotChatWrapper}
                        />
                    </>
                )}

                <Redirect to={"/not-found"} />
            </Switch>

            <NotifyMe invisible={true} />
        </div>
    );
};

export default PublicRoute;
