import BrowserCheckComponent from "./browser-check";
import UrlCheckComponent from "./url-check";

const SystemCheckComponent = () => {

    const getTitle = (title: string, subtitle: string) => (
        <div className="text-left mt-4 mb-4">
            <h2 className="text-lg text-aha-green font-bold">{title}</h2>
            <p className="text-sm text-gray-600 mt-2 mb-3">{subtitle}</p>
        </div>
    );

    return (
        <div className="container mt-4 mb-4 p-4 bg-light border border-dark rounded-3 shadow-lg ">

            {getTitle("Operating System/Browser Check", "This section will validate that your current browser is meeting the minimum requirements for using our system")}
            
            <BrowserCheckComponent />              

            <hr className="mt-4 mb-4" />

            {getTitle("Url Check", "This section will validate that the system is working fine")} 

            <UrlCheckComponent />
            
        </div>
    )
}

export default SystemCheckComponent;