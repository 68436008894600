import apolloClient from "../../../config/apolloClient";
import { IPaymentInfo } from "../../interfaces/payment-info.interface";
import { ISubscriptionInfo } from "../../interfaces/subscription-info";
import { IUser } from "../../interfaces/user.interface";
import { GetPaymentInfo, GetSubscriptionInfo, UpdateUser } from "./user.gql";

export const getUserPaymentInfo = async () => {
    const { data, errors } = await apolloClient.query<{
        getPaymentInfo: IPaymentInfo;
    }>({
        query: GetPaymentInfo,
        fetchPolicy: "network-only",
    });

    if (errors) {
        return { errors };
    }

    return { response: data.getPaymentInfo };
};

export const getUserSubscriptionInfo = async () => {
    const { data, errors } = await apolloClient.query<{
        getUserSubscriptionInfo: ISubscriptionInfo;
    }>({
        query: GetSubscriptionInfo,
        fetchPolicy: "network-only",
    });

    if (errors) {
        return { errors };
    }

    return { response: data.getUserSubscriptionInfo };
};



export const updateUser = async (value: any) => {
    const { data, errors } = await apolloClient.mutate<{
        updateUser: IUser;
    }>({
        mutation: UpdateUser,
        fetchPolicy: "network-only",
        variables: { updateUserInput: { ...value } },
    });

    if (errors) {
        return { errors };
    }

    return { response: data?.updateUser };
};

