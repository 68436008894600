import { Context, createContext } from "react";
import { IUser } from "../../interfaces/user.interface";

export interface IAuthContextState {
    user: IUser | null;
    emailForStripeCheckout: string | null;
    isLoading: boolean;
    avoidGameSetting: boolean;
    token: any | null;
}

const initialState: IAuthContextState = {
    emailForStripeCheckout: null,
    isLoading: true,
    avoidGameSetting: false,
    token: null,
    user: null
};

const AuthContext: Context<{ state: IAuthContextState; dispatch: any }> =
    createContext({
        state: initialState,
        dispatch: () => {},
    });

export default AuthContext;
