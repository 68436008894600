import React, { FormEvent, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { changePasswordRequest } from "../../shared/services/auth/auth.service";

const ChangePassword = () => {
    const { params } = useRouteMatch<{ token: string }>();

    const history = useHistory();

    const [formData, setFormData] = useState({
        newPassword: "",
    });

    const [token, setToken] = useState<string>();

    const onSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        const payload = { ...formData, token: token! };
        const { response, errors } = await changePasswordRequest(payload);
        if (response) {
            toast.success(
                "Password updated successfully. Please login with your new password."
            );
            setFormData({
                newPassword: "",
            });
        }
    };

    useEffect(() => {
        if (!params.token) {
            history.push("/");
            return;
        }

        setToken(params.token);
    }, [params]);

    return (
        <div className={"pt-14 md:pt-40 pb-12"}>
            <div className={"md:w-2/5 m-auto"}>
                <form action="#" className={"space-y-6 "} onSubmit={onSubmit}>
                    <div>
                        <input
                            required
                            minLength={6}
                            value={formData.newPassword}
                            type="password"
                            placeholder="PASSWORD"
                            className={
                                "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                            }
                            autoComplete={"new-password"}
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        newPassword: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>

                    <div className={"text-center py-6"}>
                        <button
                            className={
                                "border border-aha-green bg-aha-green text-white py-2 px-14 font-bold  hover:text-aha-green hover:bg-white transition duration-200 ease-in-out font-blanch-regular text-4xl tracking-wider whitespace-nowrap"
                            }
                        >
                            Update Password
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;
