import { useContext, useEffect, useRef } from "react";
import AuthContext from "../context/authContext/AuthContext";
import useLogoutAllTabs from "../hooks/logoutAllTabs";

const REACT_APP_PORTAL_URL = process.env[`REACT_APP_PORTAL_URL`];

const AdminPortalWrapper = () => {
    const { state: authState } = useContext(AuthContext);
    const ref = useRef<HTMLIFrameElement | null>(null);
    const { logoutAllTabs, postMessage } = useLogoutAllTabs();

    useEffect(() => {
        
        window.addEventListener('message', (event) => {
            if(event.data === 'AHA_ADMIN_LOGOUT') {
            sendMessage();
            postMessage('logout');
            window.location.href = `${REACT_APP_PORTAL_URL}/auth/login`;
            }
        });

        logoutAllTabs(sendMessage);
    }, []);
    

    const sendMessage = () => {
        if(!ref.current) {
          return;
        }
        
        ref.current?.contentWindow?.postMessage("AHA_WEB_logout", '*');
        window.location.href = `${REACT_APP_PORTAL_URL}/auth/login`;
      }

    return (
        <iframe
            ref={ref}
            style={{
                position: 'absolute',
                top: 0,
                height: '100vh',
                width: '100%',
                background: 'white'
            }}
            title="AHA Admin Portal"
            src={`${REACT_APP_PORTAL_URL}/auth/login-from-web?token=${authState.token?.accessToken}`}
        ></iframe>
    );
};

export default AdminPortalWrapper;
