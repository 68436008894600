import React from "react";
import { Link } from "react-router-dom";
import facebookIcon from "./../../../assets/images/facebook.png";
import instagramIcon from "./../../../assets/images/instagram.png";

const Footer = () => {
    return (
        <div className={"bg-aha-black py-10"}>
            <div className={"container  text-white flex gap-24"}>
                <div>
                    <div className={"flex flex-col gap-2 tracking-wider"}>
                        <Link to={"/our-story"}>Our Story</Link>
                        <Link to="/terms-and-conditions">
                            Terms & Conditions
                        </Link>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/privacy-policy#ContactUs">
                            Contact Us
                        </Link>
                    </div>
                </div>
                <div>
                    <div className={"text-lg pb-3"}>FOLLOW US</div>
                    <div className={"flex gap-4"}>
                        <a
                            href="https://www.facebook.com/At-Home-Articulation-109004648128424/"
                            target={"_blank"}
                        >
                            <img
                                src={facebookIcon}
                                alt="Facebook"
                                className={"w-6"}
                            />
                        </a>
                        <a
                            href="https://instagram.com/athomearticulation?utm_medium=copy_link"
                            target={"_blank"}
                        >
                            <img
                                src={instagramIcon}
                                alt="Instagram"
                                className={"w-6"}
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div
                className={
                    "container pt-5 border-t mt-5 text-white tracking-wider"
                }
            >
                <div>
                    Copyright &copy; {new Date().getFullYear()}, At Home
                    Articulation, Inc
                </div>
            </div>
        </div>
    );
};

export default Footer;
