import React, { FC, useContext, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { createSubscription } from "../../shared/services/stripe/stripe.service";
import { toast } from "react-toastify";
import AuthContext from "../../shared/context/authContext/AuthContext";
import { ACTIONS } from "../../shared/context/authContext/Constants";
import { useHistory } from "react-router-dom";
import DiscountCodeSection from "./discountCode";
import { IStripeProduct } from "../../shared/services/stripe/interfaces/product.interface";
import { IStripePrice } from "../../shared/services/stripe/interfaces/price.interface";
import { SubscriptionTierEnum } from "../../shared/enums/SubscriptionTier";

interface IProps {
    selectedProduct: {
        product: IStripeProduct;
        price: IStripePrice;
    };
    onDismiss: (value: boolean) => void;
}

const Checkout: FC<IProps> = (props) => {
    const { selectedProduct, onDismiss } = props;

    const { state, dispatch } = useContext(AuthContext);
    const history = useHistory();

    const stripeElements = useElements();
    const stripe = useStripe();

    const [errorMessage, setErrorMessage] = useState("");
    const [isCardReady, setIsCardReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    //coupon
    const [discountCode, setDiscountCode] = useState("");
    const [amountWithDiscount, setAmountWithDiscount] = useState("");

    const [subscriptionType] = useState(
        selectedProduct?.product?.subscriptionType
    );

    const [unitAmount] = useState(selectedProduct?.price?.unit_amount / 100);

    const handleSubmit = () => {
        setErrorMessage("");
        setIsLoading(true);
        const card = stripeElements?.getElement(CardElement);
        if (card) {
            stripe
                ?.createPaymentMethod({
                    type: "card",
                    card: card,
                })
                .then((resp) => {
                    if (resp.paymentMethod) {
                        const paymentMethodId = resp.paymentMethod.id;

                        const body: any = {
                            paymentMethodId,
                            productId: selectedProduct.product.id,
                            priceId: selectedProduct.price.id,
                            ownerEmail: state.user?.email,
                        };

                        if (discountCode) {
                            body["coupon"] = discountCode;
                        }

                        createSubscription(body)
                            .then((resp) => {
                                dispatch({
                                    type: ACTIONS.SET_USER,
                                    user: resp.response.user,
                                });

                                toast.success(
                                    "Welcome to At Home Articulation Premium!  You are now successfully subscribed."
                                );

                                onDismiss(true);

                                history.push("/app/lets-play");
                            })
                            .catch((err) => {
                                setErrorMessage(
                                    err.message ||
                                        "Error processing the payment. Please try again in a while."
                                );
                                setIsLoading(false);
                            });
                    }

                    if (resp.error) {
                        setErrorMessage(
                            "Failed to process the card. Please try again in a while."
                        );
                        setIsLoading(false);
                    }
                });
        }
    };

    return (
        <div className="space-y-4 p-6 bg-gray-200">
            <div className="bg-white rounded p-4 space-y-2">
                <div className="text-2xl font-century-gothic-bold">
                    Order Summary
                </div>
                {subscriptionType === "MONTHLY" ||
                subscriptionType === "YEARLY" ? (
                    <div>
                        <div className="flex justify-between">
                            <div className="text-aha-green">Subscription</div>
                            <div>
                                <div
                                    className={
                                        amountWithDiscount &&
                                        "text-decoration-line: line-through"
                                    }
                                >
                                    ${unitAmount}
                                </div>
                            </div>
                        </div>
                        {!!amountWithDiscount && (
                            <div className="flex justify-between">
                                <div className="text-aha-green">
                                    Promo Price
                                </div>
                                <div>
                                    <div>${amountWithDiscount}</div>
                                </div>
                            </div>
                        )}

                        <div className="capitalize">
                            {subscriptionType.toLowerCase()} Subscription
                        </div>
                    </div>
                ) : null}

                {subscriptionType ===
                SubscriptionTierEnum.CLINICIAN_SUBSCRIPTION_ANNUAL ? (
                    <div>
                        <div className="flex justify-between">
                            <div className="text-aha-green">Subscription</div>
                            <div>
                                <div
                                    className={
                                        amountWithDiscount &&
                                        "text-decoration-line: line-through"
                                    }
                                >
                                    ${unitAmount}
                                </div>
                            </div>
                        </div>
                        {!!amountWithDiscount && (
                            <div className="flex justify-between">
                                <div className="text-aha-green">
                                    Promo Price
                                </div>
                                <div>
                                    <div>${amountWithDiscount}</div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : null}
            </div>

            <div className="bg-white rounded p-4 space-y-2">
                <div className="text-2xl mb-4 font-century-gothic-bold">
                    Checkout
                </div>

                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: "18px",
                            },
                        },
                    }}
                    onChange={(ev) => {
                        if (ev.error) {
                            setErrorMessage(ev.error.message);
                        } else {
                            setErrorMessage("");
                        }

                        setIsCardReady(ev.complete);
                    }}
                    className="border py-1 px-2 border-primary-border"
                />
                {errorMessage ? (
                    <div className="text-red-500">{errorMessage}</div>
                ) : null}

                <DiscountCodeSection
                    selectedProduct={selectedProduct}
                    onDiscountCode={(value) => {
                        if (value) {
                            setDiscountCode(value.name);
                            setAmountWithDiscount(value.amount);
                        } else {
                            setDiscountCode("");
                            setAmountWithDiscount("");
                        }
                    }}
                ></DiscountCodeSection>

                <div className="flex gap-4 pt-2">
                    <button
                        disabled={!isCardReady || isLoading}
                        className={
                            "border border-aha-orange bg-aha-orange text-white py-1 px-6 hover:text-aha-orange hover:bg-white transition duration-200 ease-in-out"
                        }
                        onClick={() => handleSubmit()}
                    >
                        <div className="flex items-center">
                            {isLoading ? "Submitting..." : "Submit"}
                        </div>
                    </button>

                    <button
                        className={"border border-aha-black  py-1 px-6"}
                        onClick={() => {
                            onDismiss(false);
                            setErrorMessage("");
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Checkout;
