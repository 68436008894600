import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const HubspotChatWrapper = () => {
    const location = useLocation<any>();
    const [widgetStatus, setWidgetStatus] = useState(false);

    const ref = useRef<any>(null);

    const clicked = () => {
        window.location.href = `athome://home`;
    };

    const getQueryParams = (query: string = '') => {
        const result: Record<string, string> = {};
        new URLSearchParams(query || window.location.search).forEach(
            (value, key) => {
                result[key] = value;
            }
        );
        return result;
    };

    useEffect(() => {
        const { token, email } = getQueryParams(
            location.search.replace("?", "")
        );

        (window as any).hsConversationsSettings = {
            loadImmediately: false,
            identificationEmail: email,
            identificationToken: token || '',
        };
        setTimeout(() => {
            (window as any)?.HubSpotConversations.on('widgetClosed', (event: object) => {
                console.log(event);
                clicked();
            });
            (window as any)?.HubSpotConversations.on('widgetLoaded', (event: object) => {
                setWidgetStatus(true);
            });
            (window as any)?.HubSpotConversations?.widget?.load({ widgetOpen: true });
            (window as any)?.HubSpotConversations?.widget?.refresh();
            (window as any)?.HubSpotConversations?.widget?.open();
        }, 1000);

    }, [location.search]);

    return (
        <div className={"pt-14 md:pt-40 pb-12"}>
            <button style={{ display: "none" }} ref={ref} onClick={clicked}>
                <a href={`athome://home`}>Go App</a>
            </button>
                <div className="flex justify-center">
                    {
                        !widgetStatus && (
                            <p>Loading chat...</p>
                        )
                    }
                </div>
        </div>
    );
};

export default HubspotChatWrapper;
