import React from "react";
import parentsBanner from "./../../../assets/images/parents-banner.png";

const ParentBanner = ({
    showPricingBtn = false,
    onPricingBtnClick = null,
}: any) => {
    return (
        <div className="p-6 bg-aha-black text-white space-y-4 flex-1 relative min-h-full h-auto uppercase">
            <div className="md:px-6">
                <img src={parentsBanner} />
            </div>

            <ul className="space-y-2 pl-4 text-aha-orange lg:text-lg xl:text-xl">
                <li>
                    <span className="text-white">
                        Tips and tricks for helping your child say each sound
                        correctly
                    </span>
                </li>
                <li>
                    <span className="text-white">
                        connect directly with your child's licensed speech
                        pathologist
                    </span>
                </li>
                <li>
                    <span className="text-white">
                        a parent portal complete with speech faqs and
                        research-based parent education handouts
                    </span>
                </li>
                <li>
                    <span className="text-white">
                        numerous speech sounds and complexity levels
                    </span>
                </li>
            </ul>
            {showPricingBtn ? (
                <div className="md:absolute bottom-6 right-6 text-center">
                    <button
                        className="bg-aha-orange py-2 px-8 uppercase text-4xl font-blanch-bold tracking-widest"
                        onClick={() => {
                            if (onPricingBtnClick) {
                                onPricingBtnClick();
                            }
                        }}
                    >
                        this is for me!
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default ParentBanner;
