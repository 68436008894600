import backArrow from "./../../../assets/images/arrow.png";

const BlogDetail = ({ blog, setOpenBlog }: any) => {
    return (
        <div className="md:w-8/12 w-full">
            <div className="w-full border-8 border-aha-light-blue flex flex-col p-4 relative">
                <div
                    className="flex flex-row mb-3 cursor-pointer"
                    onClick={() => setOpenBlog(false)}
                >
                    <img className="w-5" src={backArrow} />
                    <div className="ml-2 font-century-gothic-bold text-aha-green">
                        Back
                    </div>
                </div>
                <div className="font-century-gothic-regular italic text-lg text-gray-500 h-full">
                    {blog.date}
                </div>
                <div className="mt-3 mb-3 font-century-gothic-bold text-2xl tracking-wide text-aha-black">
                    {blog.title}
                </div>
                <img src={blog.image} />
                <div className="mt-4 overflow-hidden text-gray-500">
                    {/* {blog.keywords} */}
                </div>
                <p
                    style={{ whiteSpace: "pre-line" }}
                    className="mt-4 overflow-hidden text-gray-500"
                >
                    {blog.paragraph}
                </p>
                {blog.paragraphArray.map((paragraph: any) => {
                    return (
                        <div key={paragraph.title}>
                            <h1 className="mt-8 text-xl">{paragraph.title}</h1>
                            <p
                                style={{ whiteSpace: "pre-line" }}
                                className="mt-1 text-gray-500"
                            >
                                {paragraph.paragraph}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BlogDetail;
