import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import Modal from "react-modal";
import Player from "@vimeo/player";
import { IModalOption } from "../../shared/interfaces/modal-option.interface";
import { COLORS } from "../../shared/utils/colors";

const VideoModal = ({ showModal, onClose, url }: IModalOption) => {
    const [isMobile, setIsMobile] = useState(window.outerWidth < 768);

    useEffect(() => {
        if (showModal) {
            setTimeout(() => {
                new Player("HowToVideo", {
                    url: url ? url : "https://vimeo.com/747404277",
                    responsive: true,
                    autoplay: true,
                });

                localStorage.removeItem("preview-video");
            }, 500);
        }
    }, [showModal]);

    return (
        <Modal
            isOpen={showModal}
            style={{
                content: {
                    top: "30%", //35
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -30%)",
                    width: isMobile ? "100%" : "50%", // 60
                    padding: "10px 20px",
                    border: "10px solid",
                    borderColor: COLORS.aha_orange,
                },
            }}
        >
            <div className={"flex justify-between"}>
                <div
                    className={
                        "uppercase font-blanch-bold md:text-5xl tracking-wider text-gray-500"
                    }
                >
                    How to use at home articulation
                </div>
                <div>
                    <button
                        onClick={() => {
                            onClose(false);
                        }}
                    >
                        <XIcon className={"w-10 text-gray-500"} />
                    </button>
                </div>
            </div>
            <div className={"my-2"}>
                <div id="HowToVideo"></div>
            </div>
        </Modal>
    );
};

export default VideoModal;
