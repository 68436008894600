import { XIcon } from "@heroicons/react/solid";
import { FC, useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import AuthContext from "../../../shared/context/authContext/AuthContext";
import { ISubscriptionInfo } from "../../../shared/interfaces/subscription-info";
import { IGetProductResponse } from "../../../shared/services/stripe/interfaces/get-products.interfacae";
import {
    cancelSubscription,
    getStripeProducts,
    reactivateSubscription,
} from "../../../shared/services/stripe/stripe.service";
import { formatPrice } from "../../../shared/utils/format-price";
import ClinicianProducts from "../../subscription/ClinicianProducts";
import ParentProducts from "../../subscription/ParentProducts";
import "./index.scss";

interface IProps {
    subscription: ISubscriptionInfo;
    onUpdate: () => void;
}

const isMobile = window.outerWidth < 768;

window.onload = () =>{
    const hasToShowReactivateBanner = localStorage.getItem('reloadSubscription');

    if(hasToShowReactivateBanner){
        localStorage.removeItem('reloadSubscription');
        toast.success("Subscription reactivated");
    }
} 

const SubscriptionInfo: FC<IProps> = (props) => {
    const { subscription, onUpdate } = props;
    const { state } = useContext(AuthContext);
    const [priceModal, setPriceModal] = useState(false);
    const [subscriptionUpdateLoading, setsSubscriptionUpdateLoading] = useState(false);
    const [products, setProducts] = useState<IGetProductResponse[]>([]);

    const reactivateSub = async () => {
        setsSubscriptionUpdateLoading(true);

        await reactivateSubscription();

        setsSubscriptionUpdateLoading(false);
        
    };

    const cancelSub = async () => {
        setsSubscriptionUpdateLoading(true);

        await cancelSubscription();

        setsSubscriptionUpdateLoading(false);

        toast.success("Subscription Canceled");        
    };

    const handleCancelOrReactivateSubs = async () => {
        const cancelAtPeriodEnd = subscription?.cancelAtPeriodEnd;
        const confirm = window.confirm(
            cancelAtPeriodEnd
                ? "Are you sure you want to reactivate your subscription?"
                : "By cancelling your current subscription, you’ll be downgraded to free access. This will impact the features you use day to day. Are you sure you wish to cancel?"
        );
        if (confirm) {
            try {
                if (cancelAtPeriodEnd) {
                    await reactivateSub();
                    localStorage.setItem('reloadSubscription', 'true');
                    window.location.reload();                              
                } else {
                    await cancelSub();
                    onUpdate();
                }
            } catch (error) {
                toast.error(
                    "Looks like something went wrong. Please try again later"
                );
            }
        }
    };

    useEffect(() => {
        if (state && state.user) {
            if (state.user.role === "CLINICIAN") {
                getStripeProducts().then((resp) => {
                    setProducts(resp.response);
                });
            }
        }
    }, [state]);

    return (
        <>
            {subscription && (
                <div className="bg-aha-black text-center  w-2/6">
                    <div className="py-5 px-10 text-white space-y-5">
                        <p className="text-xl font-century-gothic-bold">
                            {subscription.productName}
                        </p>
                        <p className="text-3xl">
                            {formatPrice(subscription.price)}
                        </p>

                        <p>
                            {subscription.cancelAtPeriodEnd
                                ? "Your subscription is going to finish at"
                                : "next payment:"}{" "}
                            {new Date(
                                subscription.nextPayment
                            ).toLocaleDateString()}
                        </p>
                    </div>
                    <div className=" py-5 px-10 border-2 bg-white">
                        <div className="rounded-full py-3 bg-aha-green text-white text-2xl my-3 tracking-wider">
                            SUBSCRIBED
                        </div>

                        <p
                            className=" text-lg cursor-pointer hover:underline"
                            onClick={handleCancelOrReactivateSubs}
                        >
                            {
                            subscriptionUpdateLoading ? 'Loading..' :
                            subscription.cancelAtPeriodEnd
                                ? "Reactivate my previous subscription"
                                : "Cancel my current subscription"}
                        </p>

                        {subscription.cancelAtPeriodEnd && (
                            <p
                                className=" text-lg cursor-pointer hover:underline mt-2"
                                onClick={() => setPriceModal(true)}
                            >
                                Review subscription before reactivating
                            </p>
                        )}
                    </div>

                    <Modal
                        isOpen={priceModal}
                        onRequestClose={() => setPriceModal(false)}
                        style={{
                            content: {
                                ...modalStyles,
                                width: isMobile ? "100%" : "600px",
                            },
                        }}
                    >
                        <button
                            onClick={() => {
                                setPriceModal(false);
                            }}
                            className="absolute z-10 right-1 top-1"
                        >
                            <XIcon className="w-6 h-6 text-gray-300" />
                        </button>

                        {state.user?.role === "PARENT" && (
                            <ParentProducts showSelectButtons={false} />
                        )}

                        {state.user?.role === "CLINICIAN" && (
                            <ClinicianProducts
                                showSelectButtons={false}
                                products={products}
                            />
                        )}
                    </Modal>
                </div>
            )}
        </>
    );
};

const modalStyles = {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: 20,
    border: 0,
    transform: "translate(-50%, -30%)",
    background: "#DBEDF2",
};

export default SubscriptionInfo;
