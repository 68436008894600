import { gql } from "@apollo/client";

export const AnalyticsFragment = gql`
    fragment Analytics on Analytics {
        id
        subcribedAfterFreeTrial
        lastLogin
    }
`;

export const AnalyticsIncrementalFragment = gql`
    fragment AnalyticsIncremental on AnalyticsIncremental {
        id
        type
        count
    }
`;

export const UpdateAnalytics = gql`
    mutation UpdateAnalytics($input: UpdateFlagInput!) {
        UpdateAnalytics(input: $input) {
            ...Analytics
        }
    }
    ${AnalyticsFragment}
`;

export const CreateIncrementalAnalytic = gql`
    mutation CreateIncrementalAnalytic(
        $input: CreateIncrementalAnalyticInput!
    ) {
        CreateIncrementalAnalytic(input: $input) {
            ...AnalyticsIncremental
        }
    }
    ${AnalyticsIncrementalFragment}
`;
