import { useContext, useEffect, useState } from "react";
import baloon from "../../../../assets/images/game-avatars/baloon.png";
import cyclops from "../../../../assets/images/game-avatars/cyclops.png";
import elephant from "../../../../assets/images/game-avatars/elephant.png";
import giraffe from "../../../../assets/images/game-avatars/giraffe.png";
import jeep from "../../../../assets/images/game-avatars/jeep.png";
import lion from "../../../../assets/images/game-avatars/lion.png";
import octopus from "../../../../assets/images/game-avatars/octopus.png";
import owl from "../../../../assets/images/game-avatars/owl.png";
import penguin from "../../../../assets/images/game-avatars/penguin.png";
import porcupine from "../../../../assets/images/game-avatars/porcupine.png";
import rocket from "../../../../assets/images/game-avatars/rocket.png";
import shark from "../../../../assets/images/game-avatars/shark.png";
import snail from "../../../../assets/images/game-avatars/snail.png";
import submarine from "../../../../assets/images/game-avatars/submarine.png";
import telescope from "../../../../assets/images/game-avatars/telescope.png";
import AuthContext from "../../../shared/context/authContext/AuthContext";
import { setParentProfile } from "../../../shared/services/auth/auth.service";

type Props = {
    setWhosPlaying: (boolean: boolean) => void;
    onSubmit: () => void;
};

const WhosPlayingComponent = ({ onSubmit, setWhosPlaying }: Props) => {
    const { state: authState } = useContext(AuthContext);
    const [students, setStudents] = useState<any[]>([]);
    const [avatars, setAvatars] = useState<any[]>([
        baloon,
        cyclops,
        elephant,
        giraffe,
        jeep,
        lion,
        octopus,
        owl,
        penguin,
        porcupine,
        rocket,
        shark,
        snail,
        submarine,
        telescope,
    ]);
    let studentImageUsed: any[] = [];
    // const selectedPlayedClass = 'ring-4 ring-aha-green';

    const getRandomInt = (min: number, max: number) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const getAvatar = (studentImageUsed: number[]) => {
        const randonNumber = getRandomInt(
            0,
            avatars.length - 1
        );
        if (!studentImageUsed.includes(randonNumber)) {
            studentImageUsed = [
                ...studentImageUsed,
                randonNumber,
            ];
            return avatars[randonNumber];
        }

        if (studentImageUsed.length > 13) {
            return avatars[randonNumber];
        }

        getAvatar(studentImageUsed);
    };

    useEffect(() => {
        if (authState?.user?.students) {
            setStudents(
                authState.user.students.map((st) => {

                    const avatar = getAvatar(studentImageUsed);
                    return {
                        ...st,
                        avatar,
                    };
                }) || []
            );
        }
    }, [authState?.user]);

    const onStudentSelected = async (id: string) => {
        await setParentProfile(id);
        onSubmit();
    };

    return (
        <div
            style={{ minHeight: 600 }}
            className="flex flex-col justify-between items-center container mt-8 mb-8 border-2 border-gray-300"
        >
            <div className="flex flex-col items-center">
                <h1 className="font-century-gothic-bold text-aha-orange text-5xl mt-4">
                    WHO'S PLAYING?
                </h1>
                <div className="flex flex-row flex-wrap overflow-hidden mt-8">
                    {students.map((student) => (
                        <div
                            key={student.id}
                            className="flex flex-col items center"
                        >
                            <img
                                alt="avatar"
                                className={`cursor-pointer inline-block w-36 rounded-full m-4`}
                                src={student.avatar || getAvatar(studentImageUsed)}
                                onClick={() => onStudentSelected(student.id)}
                            />
                            <p className="text-center font-century-gothic-regular">
                                {student.firstname}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <p
                className="text-aha-green mb-4 font-century-gothic-regular cursor-pointer"
                onClick={() => setWhosPlaying(false)}
            >
                BACK TO HOMEPAGE
            </p>
        </div>
    );
};

export default WhosPlayingComponent;
