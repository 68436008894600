import apolloClient from "../../../config/apolloClient";
import { SendContactEmailMutation } from "./public.gql";

export const sendContactEmail = async (input: any) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: SendContactEmailMutation,
        variables: {
            input
        },
    });

    if (errors) {
        const err = errors.length ? errors[0].message : 'Something went wrong'
        throw new Error(err);
    }

    return data.SendContactEmail;
};