import { gql } from "@apollo/client";

export const UserFragment = gql`
    fragment User on User {
        id
        firstname
        lastname
        email
        role
        firstLoginWeb
        hideLoginPopUpWeb
        clinicianInfo {
            ashaNumber
            schoolDistrict
            schoolName
            city
            state
            privatePracticeName
        }
        organization {
            organizationName
            owner {
                id
            }
        }
        subscriptionTier
        freeTrialEndsAt
        createdAt
        updatedAt
    }
`;

export const GetSubscriptionInfo = gql`
    query getUserSubscriptionInfo {
        getUserSubscriptionInfo {
            productName
            price
            nextPayment
            status
            cardNumber
            cardBrand
            cancelAtPeriodEnd
        }
    }
`;

export const GetPaymentInfo = gql`
    query getPaymentInfo {
        getPaymentInfo {
            createdAt
            pricePaid
            cardNumber
            cardBrand
            trialEnd
        }
    }
`;

export const UpdateUser = gql`
    mutation updateUser($updateUserInput: UpdateUserInput!) {
        updateUser(updateUserInput: $updateUserInput) {
            ...User
        }
    }
    ${UserFragment}
`;
