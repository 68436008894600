import { getBrowserInfo } from "./browser-info";

const BrowserCheckComponent = () => {

    const browserInfo = getBrowserInfo();

    return (
            <table className="w-full max-w-3xl border border-dark">
                <thead>
                    <tr>
                        <th className="bg-gray-100 p-3 border border-dark"></th>
                        <th className="bg-gray-100 p-3 border border-dark"></th>
                        <th className="bg-gray-100 p-3 border border-dark font-normal font-sans">Result</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="border border-dark">
                        <td className="border border-dark p-2">
                            <span className="font-bold">
                                Operating System:
                            </span>
                        </td>   
                        <td className="border border-dark text-gray-500 p-2">
                            {browserInfo?.os || "Unknown"}
                        </td>                     
                        <td className={`border border-dark p-2 text-center ${browserInfo?.os ? "text-green-600" : "text-red-600"} font-bold font-sans`}>
                            {browserInfo?.os ? "PASS" : "Action needed"}
                        </td>                     
                    </tr>
                    <tr className="border border-dark">
                        <td className="border border-dark p-2">
                            <span className="font-bold">
                                Browser:                        
                            </span>
                        </td>
                        <td className="border border-dark text-gray-500 p-2">
                            {browserInfo?.browser || "Unknown"}
                        </td>    
                        <td className={`border border-dark p-2 text-center ${browserInfo?.browser ? "text-green-600" : "text-red-600"} font-bold font-sans`}>
                            {browserInfo?.browser ? "PASS" : "Action needed"}
                        </td>   
                    </tr>
                    <tr className="border border-dark">
                        <td className="border border-dark p-2">
                            <span className="font-bold">
                                Javascript:
                            </span>
                        </td>
                        <td className="border border-dark text-gray-500 p-2">
                            Enabled
                            <noscript>Disabled.</noscript>                            
                        </td>    

                        <td className="border border-dark p-2 text-center text-green-600 font-bold font-sans">
                            PASS
                            <noscript>Action needed.</noscript>
                        </td>  
                    </tr>
                </tbody>
            </table>
    )
}

export default BrowserCheckComponent;