import React, { FC, useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { XIcon } from "@heroicons/react/solid";
import ParentBanner from "../subscription/ParentBanner";
import ParentProducts from "../subscription/ParentProducts";
import AuthContext from "../../shared/context/authContext/AuthContext";
import { getStripeProducts } from "../../shared/services/stripe/stripe.service";
import Checkout from "../subscription/Checkout";
import ClinicianBanner from "../subscription/ClinicianBanner";
import ClinicianProducts from "../subscription/ClinicianProducts";
import { IGetProductResponse } from "../../shared/services/stripe/interfaces/get-products.interfacae";
import { IStripeProduct } from "../../shared/services/stripe/interfaces/product.interface";
import { IStripePrice } from "../../shared/services/stripe/interfaces/price.interface";

interface IProps {
    showModal: boolean;
    skipRoleDescription?: boolean;
    onClose: (value?: { approved: boolean }) => void;
}

const SubscriptionModal: FC<IProps> = (props) => {
    const { onClose, showModal, skipRoleDescription } = props;
    const [isMobile] = useState(window.outerWidth < 768);

    const [showPrices, setShowPrices] = useState(false);

    const [products, setProducts] = useState<IGetProductResponse[]>([]);

    const [selectedProduct, setSelectedProduct] = useState<{
        product: IStripeProduct;
        price: IStripePrice;
    }>();

    const { state } = useContext(AuthContext);

    const [userRole, setUserRole] = useState(state?.user?.role);

    const [showCheckoutModal, setShowCheckoutModal] = useState(false);

    useEffect(() => {
        setUserRole(state?.user?.role);
    }, [state]);

    useEffect(() => {
        getStripeProducts().then((resp) => {
            setProducts(resp.response);
        });
    }, []);

    const renderParentProducts = () => (
        <>
            {showPrices || skipRoleDescription ? (
                <div className="my-6 mr-12 ml-6">
                    <ParentProducts
                        onSelect={(price: any) => {
                            products.forEach((product: any) => {
                                product.prices.forEach((pricex: any) => {
                                    if (pricex.unit_amount === price) {
                                        setSelectedProduct(() => {
                                            return {
                                                product: product.product,
                                                price: pricex,
                                            };
                                        });

                                        setShowCheckoutModal(true);
                                    }
                                });
                            });
                        }}
                    />
                </div>
            ) : (
                <ParentBanner
                    showPricingBtn={true}
                    onPricingBtnClick={() => {
                        setShowPrices(true);
                    }}
                />
            )}
        </>
    );

    const renderClinicianProducts = () => (
        <>
            {showPrices || skipRoleDescription ? (
                <div className="my-6 mr-12 ml-6">
                    <ClinicianProducts
                        products={products}
                        onSelectProduct={(value) => {
                            setSelectedProduct({
                                product: value.product,
                                price: value.prices[0],
                            });
                            setShowCheckoutModal(true);
                        }}
                    />
                </div>
            ) : (
                <ClinicianBanner
                    showPricingBtn={true}
                    onPricingBtnClick={() => {
                        setShowPrices(true);
                    }}
                />
            )}
        </>
    );

    return (
        <>
            <Modal
                isOpen={showModal}
                style={{
                    content: {
                        top: "30%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        padding: 0,
                        border: 0,
                        transform: "translate(-50%, -30%)",
                        width: isMobile
                            ? "100%"
                            : showPrices || skipRoleDescription
                            ? "600px"
                            : "400px",
                        height: isMobile ? "100%" : "700px",
                        background: "#DBEDF2",
                    },
                }}
            >
                <button
                    onClick={() => {
                        onClose();
                        setShowPrices(false);
                    }}
                    className="absolute z-10 right-1 top-1"
                >
                    <XIcon className="w-6 h-6 text-gray-300" />
                </button>

                {userRole === "PARENT" && renderParentProducts()}

                {userRole === "CLINICIAN" && renderClinicianProducts()}
            </Modal>

            <Modal
                ariaHideApp={false}
                isOpen={showCheckoutModal}
                onClose={() => setShowCheckoutModal(false)}
                style={{
                    content: {
                        top: "30%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -30%)",
                        width: isMobile ? "100%" : 600,
                        padding: 0,
                        border: 0,
                    },
                }}
            >
                {selectedProduct && (
                    <Checkout
                        selectedProduct={selectedProduct}
                        onDismiss={(purchased: boolean) => {
                            setShowCheckoutModal(false);
                            if (purchased) {
                                onClose({ approved: true });
                                setShowPrices(false);
                            }
                        }}
                    />
                )}
            </Modal>
        </>
    );
};

export default SubscriptionModal;
