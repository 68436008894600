import React, { FC, useEffect, useState } from "react";
import { IGetProductResponse } from "../../shared/services/stripe/interfaces/get-products.interfacae";
import { formatPrice, getPricePerMonth } from "../../shared/utils/format-price";

interface IProps {
    products: IGetProductResponse[];
    showSelectButtons?: boolean;
    onSelectProduct?: (value: IGetProductResponse) => void;
}

const CLINICIAN_ANNUAL_ID =
    process.env["REACT_APP_CLINICIAN_ANNUAL_SUBSCRIPTION"];

const ClinicianProducts: FC<IProps> = (props) => {
    const { products, showSelectButtons = true, onSelectProduct } = props;

    const [clinicianProducts, setClinicianProducts] = useState<
        IGetProductResponse[]
    >([]);

    useEffect(() => {
        if (products) {
            const clinicianProductsFiltered = products.filter((p) => {
                return p.product.id === CLINICIAN_ANNUAL_ID;
            });

            setClinicianProducts(clinicianProductsFiltered);
        }
    }, [products]);

    return (
        <div className="space-y-6 text-center text-aha-black">
            <div className="font-century-gothic-bold uppercase  text-2xl">
                We understand how precious your
                <br /> budget for new materials can be...
            </div>

            {clinicianProducts.map((product) => {
                return (
                    <div
                        key={product.product.id}
                        className="bg-white text-center md:w-3/5 mx-auto"
                    >
                        <div className="p-4">
                            <div className="mb-10">
                                <div className="text-aha-orange font-blanch-bold tracking-widest text-5xl">
                                    Yearly
                                </div>
                                <div className="tracking-wider">
                                    subscription
                                </div>
                            </div>
                            <div className="text-4xl mb-10">
                                {formatPrice(product.prices[0].unit_amount)}
                                /annually
                            </div>
                        </div>

                        {showSelectButtons && (
                            <button
                                className="w-full block bg-aha-green py-4 text-white font-blanch-regular tracking-wider text-5xl"
                                onClick={() => {
                                    onSelectProduct && onSelectProduct(product);
                                }}
                            >
                                SELECT
                            </button>
                        )}
                    </div>
                );
            })}
            <div className="text-xl">
                Sending speech homework just <br />
                got a whole lot easier!
            </div>
        </div>
    );
};

export default ClinicianProducts;
