import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
    loginRequest,
    registerRequest,
} from "../../shared/services/auth/auth.service";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AUTH_ROUTES } from "../../shared/routes/Constants";
import banner from "../../../assets/images/parent-registration-banner.png";
import { toast } from "react-toastify";
import AuthContext from "../../shared/context/authContext/AuthContext";
import { ACTIONS } from "../../shared/context/authContext/Constants";
import { IUser } from "../../shared/interfaces/user.interface";

const ParentRegister = () => {
    const { dispatch } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();
    const [freeUser, setFreeUser] = useState(false);
    const [terms, setTerms] = useState(false);

    const [signUpSchema, setSignUpSchema] = useState(
        Yup.object().shape({
            firstname: Yup.string().required("First name is required"),
            lastname: Yup.string().required("Last name is required"),
            email: Yup.string()
                .email("Invalid email")
                .required("Email is required."),
            password: Yup.string().min(6).required("Password is required."),
            confirm_password: Yup.string()
                .min(6)
                .required("Confirm Password is required.")
                .equals([Yup.ref("password")], "Passwords must match"),
        })
    );

    const handleLogin = async (values: { email: string; password: string }) => {
        const { response, errors } = await loginRequest({
            ...values,
            email: values.email.toLowerCase(),
        });

        if (errors) {
            toast.error({
                message:
                    "There was an error logging you in automatically, please try it yourself.",
            });
        } else if (response) {
            const user: IUser = response.user;

            dispatch({
                type: ACTIONS.LOGIN,
                token: {
                    accessToken: response.accessToken,
                    refreshToken: response.refreshToken,
                },
            });

            dispatch({
                type: ACTIONS.SET_USER,
                user: response.user,
            });

            if (user && !user.firstLoginWeb) {
                localStorage.setItem("preview-video", "1");
            }

            history.push("/app/lets-play");
        }
    };

    useEffect(() => {
        if (location) {
            if (location.pathname === AUTH_ROUTES.FREE_REGISTER) {
                setFreeUser(true);
            } else {
                setFreeUser(false);
            }
        }
    }, [location]);

    return (
        <div className={"pt-14 pb-12"}>
            <img
                className="mb-10 w-11/12  md-w-7/12 m-auto"
                src={banner}
                alt="parent registration banner"
            />
            <div className={"md:w-2/5 m-auto"}>
                <Formik
                    initialValues={{
                        firstname: "",
                        lastname: "",
                        email: "",
                        password: "",
                    }}
                    validationSchema={signUpSchema}
                    onSubmit={async (values, { setFieldError }) => {
                        if (!terms) {
                            toast.error(
                                "You must accept the terms and conditions."
                            );
                            return;
                        }

                        const payload: any = {
                            ...values,
                            email: values.email.toLowerCase(),
                        };

                        delete payload.confirm_password;

                        if (freeUser) {
                            payload["freeUser"] = true;
                        }

                        const { message, errors } = await registerRequest(
                            payload
                        );

                        if (errors) {
                            const errorMessage = errors
                                .map((x: any) => x.message)
                                .join("");

                            if (errorMessage === "Email already taken!") {
                                setFieldError(
                                    "email",
                                    "Email is already registered. Please sign in."
                                );
                            }
                        } else if (message) {
                            const values = {
                                email: payload.email,
                                password: payload.password,
                            };

                            handleLogin(values);
                        }
                    }}
                >
                    {({ errors, touched }) => (
                        <Form className={"space-y-6"}>
                            <div>
                                <Field
                                    name="firstname"
                                    autoComplete={"new-password"}
                                    placeholder="FIRST NAME"
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="firstname"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>
                            <div>
                                <Field
                                    name="lastname"
                                    autoComplete={"new-password"}
                                    placeholder="LAST NAME"
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="lastname"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>
                            <div>
                                <Field
                                    name="email"
                                    autoComplete={"new-password"}
                                    placeholder="EMAIL"
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="email"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>
                            <div>
                                <Field
                                    name="password"
                                    placeholder="PASSWORD"
                                    type="password"
                                    autoComplete={"new-password"}
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="password"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>

                            <div>
                                <Field
                                    name="confirm_password"
                                    placeholder="CONFIRM PASSWORD"
                                    type="password"
                                    autoComplete={"new-password"}
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="confirm_password"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>

                            <div>
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="terms"
                                        className="w-4 h-4"
                                        onChange={(e) =>
                                            setTerms(e.target.checked)
                                        }
                                    />
                                    <label
                                        htmlFor="horizontal-list-radio-license-3"
                                        className="w-full py-3 ml-2 text-xs text-aha-green"
                                    >
                                        I agree to the{" "}
                                        <Link
                                            className="underline text-blue-400"
                                            to={"/terms-and-conditions"}
                                        >
                                            terms/conditions
                                        </Link>{" "}
                                        and{" "}
                                        <Link
                                            className="underline text-blue-400"
                                            to={"/privacy-policy"}
                                        >
                                            privacy policy
                                        </Link>
                                        .
                                    </label>
                                </div>
                            </div>

                            <div className={"text-center py-6"}>
                                <button
                                    className={
                                        "border border-aha-green bg-aha-green text-white py-2 px-14 font-bold  hover:text-aha-green hover:bg-white transition duration-200 ease-in-out font-blanch-regular text-4xl tracking-wider"
                                    }
                                >
                                    REGISTER
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ParentRegister;
