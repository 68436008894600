import React from "react";
import { XIcon } from "@heroicons/react/solid";
import Modal from "react-modal";
import { COLORS } from "../../shared/utils/colors";
import { IMessage } from "../interfaces/messages.interface";
import { readMessages } from "../services/messages/messages.service";

interface IProps {
    showModal: boolean;
    messages: IMessage[];
    onClose: () => void;
}

const isMobile = window.outerWidth < 768;

const MessageModal = (props: IProps) => {
    const { showModal, messages, onClose } = props;

    const readAllMessages = async () => {
        const ids = messages.map((m) => m.id);
        await readMessages(ids);
    };

    const handleOnClose = async () => {
        await readAllMessages();
        onClose();
    };

    const renderMessageItem = (item: IMessage, idx: number) => {
        return (
            <div key={item.id}>
                <p>- {item.message}</p>
            </div>
        );
    };

    return (
        <Modal
            isOpen={showModal}
            style={{
                content: {
                    top: "30%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -30%)",
                    width: isMobile ? "100%" : "50%",
                    padding: "10px 20px",
                    border: "10px solid",
                    borderColor: COLORS.aha_orange,
                },
            }}
        >
            <div>
                <div className={"flex justify-between"}>
                    <div></div>
                    <div>
                        <button onClick={handleOnClose}>
                            <XIcon className={"w-10 text-gray-500"} />
                        </button>
                    </div>
                </div>
                <div>
                    {messages.map((item, idx) => renderMessageItem(item, idx))}
                </div>
                <div className="flex gap-5 mt-20">
                    <button
                        onClick={handleOnClose}
                        className={
                            "border border-aha-green bg-aha-green text-white py-1 px-10 font-bold  hover:text-aha-green hover:bg-white transition duration-200 ease-in-out font-blanch-regular text-2xl tracking-wider whitespace-nowrap"
                        }
                    >
                        ok
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default MessageModal;
