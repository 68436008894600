export const ROOT_ROUTES = {
    HOME: "home",
    AUTH: "auth",
};

export const AUTH_ROUTES = {
    BASE: "/auth",
    LOGIN: "/login",
    REGISTER: "/register",
    CLINICIAN_REGISTER: "/clinician-register",
    FREE_REGISTER: "/premium-access-registration",
    FREE_REGISTER_CLINICIAN: "/premium-access-registration-clinician",
    FORGOT_PASSWORD: "/forgot-password",
    CHANGE_PASSWORD: "/change-password",
    VERIFY_EMAIL: "/verify-email",
};

export const STORAGE_KEYS = {
    GAME_SETTINGS: "game_settings"
}
