export enum AppRoles {
    ADMIN = "ADMIN",
    USER = "USER",
    DEVELOPER = "DEVELOPER",
    PARENT = "PARENT",
    CLINICIAN = "CLINICIAN",
    STUDENT = "STUDENT",
}

export enum CustomManagementRole {
    B2B_ACCOUNT_OWNER = "B2B Account Owner",
    B2B_CLINICIAN = "B2B Clinician",
    B2B_PARENT= "B2B Parent",
    B2B_STUDENT= "B2B Student",
    B2C_CLINICIAN = "B2C Clinician",
    B2C_PARENT= "B2C Parent",
    B2C_STUDENT= "B2C Student",
    ADMIN = "ADMIN"
}
