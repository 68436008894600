import ReactModal from "react-modal";
import { IModalOption } from "../../shared/interfaces/modal-option.interface";
import XIcon from "@heroicons/react/solid/XIcon";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { contactSchema } from "./utils/schemas";
import { useState } from "react";
import InputMask from 'react-input-mask';
import { sendContactEmail } from "../../shared/services/public/public.service";
import { toast } from "react-toastify";

ReactModal.defaultStyles.overlay.backgroundColor = "#00000060";

const ContactUsModal = ({ showModal, onClose }: IModalOption) => {

    const [isMobile] = useState(window.outerWidth < 768);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <ReactModal
            isOpen={showModal}
            style={{
                content: {
                    top: "30%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -30%)",
                    minWidth: '250px',
                    width: isMobile ? "80%" : "40%",
                },
            }}
        >
            <div className={"flex justify-end"}>
                <button
                    onClick={() => {
                        onClose(false);
                    }}
                >
                    <XIcon className={"w-10 text-gray-500"} />
                </button>
            </div>
            <div className={"my-2"}>
            <div className={""}>
                <Formik
                    initialValues={{
                        name: "",
                        phone: "",
                        email: "",
                        message: "",
                    }}
                    validationSchema={contactSchema}
                    onSubmit={async (values) => {
                        setIsLoading(true)
                        try {
                            await sendContactEmail(values);   
                            onClose(false);
                            toast.success("Email sent successfully");
                        } catch (err) {
                            toast.error(err as string);
                        }
                        finally {
                            setIsLoading(false);
                        }
                    }}
                >
                    {({ setFieldValue, errors, touched }) => (
                        <Form className={"space-y-6"}>
                            <div>
                                <Field
                                    name="name"
                                    placeholder="NAME"
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="name"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>
                            <div>
                                <Field
                                    name="phone"
                                    placeholder="PHONE NUMBER"
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }>
                                        {({ field }: any) => (
                                                <InputMask
                                                {...field}
                                                className={
                                                    "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                                }
                                                mask="(999) 999-9999"
                                                placeholder="PHONE NUMBER"
                                                type="text"
                                                onChange={(e: any) => {
                                                    setFieldValue(
                                                        'phone',
                                                        e.target.value.replace(/\D/g, '') // removes all non-numeric characters
                                                    );
                                                }}
                                            />
                                        )}
                                </Field>
                                <ErrorMessage
                                    name="phone"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>
                            <div>
                                <Field
                                    name="email"
                                    placeholder="EMAIL"
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="email"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>
                            <div>
                                <Field
                                    name="message"
                                    placeholder="MESSAGE (Optional)"
                                    type="text"
                                    component="textarea"
                                    rows={5}
                                    className={
                                        "text-aha-green border-b-2 border-aha-orange focus:outline-none w-full p-2 placeholder-aha-green placeholder-opacity-50 bg-white tracking-widest font-century-gothic-bold"
                                    }
                                />
                                <ErrorMessage
                                    name="message"
                                    component={"div"}
                                    className="text-red-500 capitalize"
                                />
                            </div>

                            <div className={"text-center py-6"}>
                                <button
                                    type="submit"
                                    className={
                                        "border border-aha-green bg-aha-green text-white py-2 px-14 font-bold  hover:text-aha-green hover:bg-white transition duration-200 ease-in-out font-blanch-regular text-4xl tracking-wider"
                                    }
                                >
                                    {isLoading ? 'Sending...' : 'SUBMIT'}                                    
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            </div>
        </ReactModal>
    );
};

export default ContactUsModal;