import React, { FC, useState } from "react";
import "./index.scss";
import { formatPrice } from "../../../shared/utils/format-price";
import Modal from "react-modal";
import ParentProducts from "../../subscription/ParentProducts";
import { IPaymentInfo } from "../../../shared/interfaces/payment-info.interface";

interface IProps {
    payment: IPaymentInfo;
    onUpdate: () => void;
}

const isMobile = window.outerWidth < 768;

const PaymentInfo: FC<IProps> = (props) => {
    const { payment, onUpdate } = props;
    const [priceModal, setPriceModal] = useState(false);

    return (
        <>
            {payment && (
                <div className="bg-aha-black text-center  w-2/6">
                    <div className="py-5 px-10 text-white space-y-5">
                        <p className="text-xl font-century-gothic-bold">
                            Clinician Payment
                        </p>
                        <p className="text-3xl">
                            {formatPrice(payment.pricePaid)}
                        </p>
                    </div>
                    <div className=" py-5 px-10 border-2 bg-white">
                        <div className="rounded-full py-3 bg-aha-green text-white text-2xl my-3 tracking-wider">
                            {payment.trialEnd ? "TRIAL" : "PAYED"}
                        </div>
                    </div>

                    <Modal
                        isOpen={priceModal}
                        onRequestClose={() => setPriceModal(false)}
                        style={{
                            content: {
                                ...modalStyles,
                                width: isMobile ? "100%" : "600px",
                            },
                        }}
                    >
                        <ParentProducts showSelectButtons={false} />
                    </Modal>
                </div>
            )}
        </>
    );
};

const modalStyles = {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: 20,
    border: 0,
    transform: "translate(-50%, -30%)",
    background: "#DBEDF2",
};

export default PaymentInfo;
