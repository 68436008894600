import React, { useState } from "react";

import { data } from "./data";
import BlogRight from "./BlogRight";
import BlogDetail from "./BlogDetail";

export const Blog = () => {
    const [openBlog, setOpenBlog] = useState<boolean>(false);
    const [selectedBlog, setSelectedBlog] = useState<any>();
    const [searchedTerm, setSearchedTerm] = useState("");

    const getFirst100 = (str: string) => {
        return str.split(" ").slice(0, 30).join(" ");
    };

    const includesString = (stringA: string, stringB: string) =>
        stringA.toLocaleLowerCase().includes(stringB.toLocaleLowerCase());

    return (
        <div>
            <div className="py-12">
                <div className="container">
                    <div className="flex flex-row">
                        {openBlog ? (
                            <BlogDetail
                                blog={selectedBlog}
                                setOpenBlog={setOpenBlog}
                            />
                        ) : (
                            <div className="md:w-8/12 w-full">
                                <div className="space-y-1 flex items-center">
                                    <div className="text-gray-500 mr-2">
                                        SEARCH
                                    </div>
                                    <div className="border border-gray-300 w-full">
                                        <input
                                            type="text"
                                            value={searchedTerm}
                                            className="w-full py-1 px-2 "
                                            name="NAME"
                                            id="mce-NAME"
                                            onChange={(e) =>
                                                setSearchedTerm(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <>
                                    {data
                                        .filter(({ title }) =>
                                            includesString(
                                                `${title}`,
                                                searchedTerm
                                            )
                                        )
                                        .map((blog) => {
                                            return (
                                                <div className="mt-5 w-full border-8 border-aha-light-blue flex flex-row p-4 relative">
                                                    <div className="w-2/6 md:block hidden">
                                                        <img
                                                            className="w-60"
                                                            src={blog.image}
                                                        />
                                                    </div>
                                                    <div
                                                        className="md:w-4/6 w-full cursor-pointer ml-5 mb-2"
                                                        onClick={() => {
                                                            setOpenBlog(true);
                                                            setSelectedBlog(
                                                                blog
                                                            );
                                                        }}
                                                    >
                                                        <div className="font-century-gothic-regular italic text-lg text-gray-500">
                                                            {blog.date}
                                                        </div>
                                                        <div className="font-century-gothic-bold text-xl tracking-wide text-aha-black">
                                                            {blog.title}
                                                        </div>
                                                        <div className="w-full overflow-hidden mt-1">
                                                            <div className="overflow-hidden text-gray-500">
                                                                {getFirst100(
                                                                    blog.paragraph
                                                                )}
                                                                ...
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="italic text-gray-400 tracking-wide absolute bottom-0 right-3 cursor-pointer"
                                                        onClick={() => {
                                                            setOpenBlog(true);
                                                            setSelectedBlog(
                                                                blog
                                                            );
                                                        }}
                                                    >
                                                        READ MORE...
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </>
                            </div>
                        )}

                        <div className="w-4/12 hidden md:flex justify-center items-start">
                            <BlogRight />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog;
