import React, { useContext, useEffect, useRef, useState } from "react";
import { createIncrementalAnalytic } from "../../shared/services/analytics/analytics.service";
import { AnalyticTypesEnum } from "../../shared/enums/analytics-types.enum";
import AuthContext from "../../shared/context/authContext/AuthContext";
import { AnalyticOriginEnum } from "../../shared/enums/analytics-origin.enum";

const LOCAL_STORAGE_KEY = "AnalyticsStartTime";
const INACTIVE_MILI = 59000;
const UPDATE_EVERY_MILI = 60000;

const useActive = (duration: number): boolean => {
    const [inactive, setInactive] = useState(true);
    const timeoutRef = useRef<any>();

    function handleUserActivity() {
        setInactive(true);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => setInactive(false), duration);
    }

    useEffect(() => {
        const events = ["click", "scroll"];

        for (let event of events) {
            window.addEventListener(event, handleUserActivity);
        }

        return () => {
            for (let event of events) {
                window.removeEventListener(event, handleUserActivity);
            }
        };
    }, []);

    return inactive;
};

const AnalyticsProvider = ({ children }: any) => {
    const { state } = useContext(AuthContext);

    const active = useActive(INACTIVE_MILI);

    const [startTime, setStartTime] = useState<number>(0);
    const [isRunning, setIsRunning] = useState(true);

    const updateAnalytics = async () => {
        try {
            const { response } = await createIncrementalAnalytic({
                type: AnalyticTypesEnum.ACTIVE_MINUTES,
                origin: AnalyticOriginEnum.WEB,
                count: 1,
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleStart = () => {
        const now = Date.now();
        setStartTime(now);
        setIsRunning(true);
        localStorage.setItem(LOCAL_STORAGE_KEY, now.toString());
    };

    const handleStop = () => {
        setIsRunning(false);
        setStartTime(0);
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    };

    useEffect(() => {
        if (process.env.REACT_APP_ENV !== "local") {
            if (state && state.user) {
                if (active) {
                    handleStart();
                } else {
                    handleStop();
                }
            }
        }
    }, [active]);

    useEffect(() => {
        if (process.env.REACT_APP_ENV !== "local") {
            // Actualizar el tiempo activo si el temporizador está en marcha
            let timer: any;
            if (isRunning) {
                timer = setInterval(() => {
                    if (state && state.user) {
                        updateAnalytics();
                    }
                }, UPDATE_EVERY_MILI);
            }
            return () => clearInterval(timer);
        }
    }, [isRunning, startTime]);

    return <></>;
};

export default AnalyticsProvider;
