import apolloClient from "../../../config/apolloClient";
import { IDiscountCode } from "../../interfaces/discount-code.interface";
import { IGetProductResponse } from "./interfaces/get-products.interfacae";
import {
    createSubscriptionMutation,
    GetAllDiscountCode,
    GetDiscountCode,
    getStripeProductByIdQuery,
    getStripeProductsQuery,
    CancelSubscriptionMutation,
    ReactivateSubscriptionMutation,
    ChangePaymentMethod,
} from "./stripe.gql";

export const getStripeProducts = async () => {
    const { data, errors } = await apolloClient.query<{
        getStripeProducts: IGetProductResponse[];
    }>({
        query: getStripeProductsQuery,
    });

    if (errors) {
        throw errors;
    }

    return { response: data.getStripeProducts };
};

export const getStripeProductById = async (id: string) => {
    const { data, errors } = await apolloClient.query({
        query: getStripeProductByIdQuery,
    });

    if (errors) {
        throw errors;
    }

    return { response: data.getStripeProductById };
};

export const createSubscription = async (payload: any) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: createSubscriptionMutation,
        variables: {
            input: {
                ...payload,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.createSubscription };
};

export const cancelSubscription = async () => {
    const { data, errors } = await apolloClient.mutate<{
        cancelSubscription: { message: string };
    }>({
        mutation: CancelSubscriptionMutation,
    });

    if (errors) {
        return { errors };
    }

    return { response: data?.cancelSubscription };
};

export const reactivateSubscription = async () => {
    const { data, errors } = await apolloClient.mutate<{
        reActivateSubscription: { message: string };
    }>({
        mutation: ReactivateSubscriptionMutation,
    });

    if (errors) {
        return { errors };
    }

    return { response: data?.reActivateSubscription };
};

export const changePaymentMethod = async (id: string) => {
    const { data, errors } = await apolloClient.mutate<{
        changePaymentMethod: { message: string };
    }>({
        mutation: ChangePaymentMethod,
        variables: {
            input: {
                newPaymentMethodId: id,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data?.changePaymentMethod };
};

export const getDiscountCode = async (
    name: string
): Promise<{ response: IDiscountCode }> => {
    const { data, errors } = await apolloClient.query({
        query: GetDiscountCode,
        variables: {
            input: { discountCode: name },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.GetDiscountCode };
};

export const getAllDiscountCode = async (): Promise<{
    response: IDiscountCode[];
}> => {
    const { data, errors } = await apolloClient.query({
        query: GetAllDiscountCode,
    });

    if (errors) {
        throw errors;
    }

    return { response: data.getAllDiscountCodes };
};
