import apolloClient from "../../../config/apolloClient";

import { IMessage } from "../../interfaces/messages.interface";
import { GetMessages, ReadMessages } from "./messages.gql";

export const getMessages = async () => {
    const { data, errors } = await apolloClient.query<{
        GetMessages: IMessage[];
    }>({
        query: GetMessages,
        fetchPolicy: "network-only",
    });

    if (errors) {
        return { errors };
    }

    return { response: data.GetMessages };
};

export const readMessages = async (messageIds: string[]) => {
    const { data, errors } = await apolloClient.mutate<{
        ReadMessage: { message: string };
    }>({
        mutation: ReadMessages,
        variables: { input: { messageIds } },
    });

    if (errors) {
        return { errors };
    }

    return { response: data?.ReadMessage };
};
