import * as Bowser from "bowser";
import { capitalizeWord } from "../../shared/utils/utils";

export const getBrowserInfo = () => {    
    const browserInfo = Bowser.parse(window.navigator.userAgent);
    
    return {
        os: `${capitalizeWord(browserInfo.os.name)} ${browserInfo.os.version} (${capitalizeWord(browserInfo.platform.type)})`,
        browser: `${browserInfo.browser.name} ${browserInfo.browser.version}`
    }
}
    
