import React, { FC, useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import Modal from "react-modal";
import { IUser } from "../../shared/interfaces/user.interface";
import { updateFlags } from "../../shared/services/flags/flags.service";
import {
    IFlagsEditables,
    IFlagsResponse,
} from "../../shared/interfaces/flags.interface";
import SubscriptionModal from "../letsPlay/SubscriptionModal";
import { COLORS } from "../../shared/utils/colors";

type contentType = "7_days" | "3_days" | "last_day" | "free_trial_ended";

interface IProps {
    showModal: boolean;
    user: IUser;
    data: IFlagsResponse;
    onClose: () => void;
}

const isMobile = window.outerWidth < 768;

const FreeTrialReminderModal: FC<IProps> = (props) => {
    const { showModal, onClose, user, data } = props;
    const [contenetType, setContentType] = useState<contentType>("7_days");
    const [disableButtons, setDisablebuttons] = useState(false);
    const [subscribeButtons, setSubscribeButtons] = useState(false);
    const [subscriptionModal, setSubscriptionModal] = useState(false);

    const updFlags = async () => {
        const params: IFlagsEditables = {};

        if (contenetType === "7_days") {
            params["freeTrial7DaysReminding"] = true;
        } else if (contenetType === "3_days") {
            params["freeTrial7DaysReminding"] = true;
            params["freeTrial3DaysReminding"] = true;
        } else if (contenetType === "last_day") {
            params["freeTrial7DaysReminding"] = true;
            params["freeTrial3DaysReminding"] = true;
            params["freeTrialLastReminding"] = true;
        } else if (contenetType === "free_trial_ended") {
            params["freeTrial7DaysReminding"] = true;
            params["freeTrial3DaysReminding"] = true;
            params["freeTrialLastReminding"] = true;
            params["shouldShowFreeTrialEndReminding"] = false;
        }

        if (Object.keys(params).length > 0) {
            await updateFlags(params);
        }
    };

    const setContent = () => {
        if (data.showFreeTrialEndReminding) {
            setContentType("free_trial_ended");
            setSubscribeButtons(true);
        } else if (data.show7DaysReminding) {
            setContentType("7_days");
        } else if (data.show3DaysReminding) {
            setContentType("3_days");
        } else if (data.showLastReminding) {
            setContentType("last_day");
        }
    };

    const handleOnClose = async () => {
        setDisablebuttons(true);
        await updFlags();
        onClose();
    };

    const handleSubscribe = async () => {
        await updFlags();
        setSubscriptionModal(true);
    };

    const handleClickHere = () => {
        handleSubscribe();
    };

    useEffect(() => {
        if (user) {
            setContent();
        }
    }, [data, user]);

    const renderContent = () => {
        if (contenetType) {
            if (contenetType === "7_days") {
                return (
                    <div className="text-xl">
                        We hope you're enjoying At Home Articulation! You have 7
                        days left in your free trial. Already seeing the
                        benefits? Subscribe{" "}
                        <span
                            className="underline cursor-pointer"
                            onClick={handleClickHere}
                        >
                            HERE
                        </span>
                        .<br></br>
                        <br></br>
                        Please let us know if you have any questions, you can
                        reach us at support@athomearticulation.com
                    </div>
                );
            } else if (contenetType === "3_days") {
                return (
                    <div className="text-xl">
                        How's your free trial going? You have 3 more days of
                        premium features! Our goals are to empower parents,
                        lighten the load of clinicians, and to make speech
                        practice fun for kids. Together we can bridge the gap
                        between home and the speech room. Click{" "}
                        <span
                            className="underline cursor-pointer"
                            onClick={handleClickHere}
                        >
                            HERE
                        </span>{" "}
                        to subscribe.
                        <br />
                        <br />
                        We're here to help, let us know if you have any
                        questions at support@athomearticulation.com
                    </div>
                );
            } else if (contenetType === "last_day") {
                return (
                    <div className="text-xl">
                        Your free trial ends tomorrow! As of tomorrow you will
                        no longer have access to the premium features. Studies
                        show that kids who practice their speech at home move
                        more quickly through the speech process. Let's keep the
                        momentum going! Click{" "}
                        <span
                            className="underline cursor-pointer"
                            onClick={handleClickHere}
                        >
                            HERE
                        </span>{" "}
                        to subscribe.
                    </div>
                );
            } else if (contenetType === "free_trial_ended") {
                return (
                    <div className="text-xl">
                        Thank you for using At Home Articulation! Our goals are
                        to empower parents, lighten the load of clinicians and
                        to make speech practice fun for kids. Let's keep that
                        momentum going!
                    </div>
                );
            }
        }
    };

    return (
        <Modal
            isOpen={showModal}
            style={{
                content: {
                    top: "30%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -30%)",
                    width: isMobile ? "100%" : "50%",
                    padding: "10px 20px",
                    border: "10px solid",
                    borderColor: COLORS.aha_orange,
                },
            }}
        >
            <div className={"flex justify-between"}>
                <div
                    className={
                        "uppercase font-blanch-bold md:text-5xl tracking-wider text-gray-500"
                    }
                >
                    Free Trial Reminder
                </div>
                <div>
                    <button
                        disabled={disableButtons}
                        onClick={() => {
                            handleOnClose();
                        }}
                    >
                        <XIcon className={"w-10 text-gray-500"} />
                    </button>
                </div>
            </div>
            <div className={"my-2"}>
                <p>{renderContent()}</p>

                {subscribeButtons && (
                    <div className="flex gap-5 mt-20">
                        <button
                            disabled={disableButtons}
                            onClick={handleSubscribe}
                            className={
                                "border border-aha-green bg-aha-green text-white py-1 px-10 font-bold  hover:text-aha-green hover:bg-white transition duration-200 ease-in-out font-blanch-regular text-2xl tracking-wider whitespace-nowrap"
                            }
                        >
                            Subscribe
                        </button>
                        <button
                            disabled={disableButtons}
                            onClick={() => handleOnClose()}
                            className={
                                "border border-aha-green bg-aha-green text-white py-1 px-10 font-bold  hover:text-aha-green hover:bg-white transition duration-200 ease-in-out font-blanch-regular text-2xl tracking-wider whitespace-nowrap"
                            }
                        >
                            No thanks, I'll use the free version
                        </button>
                    </div>
                )}
            </div>
            {subscriptionModal && (
                <SubscriptionModal
                    skipRoleDescription={true}
                    showModal={subscriptionModal}
                    onClose={(value) => {
                        setSubscriptionModal(false);
                        if (value && value.approved) {
                            onClose();
                        }
                    }}
                />
            )}
        </Modal>
    );
};

export default FreeTrialReminderModal;
