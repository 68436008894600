import { useEffect, useState } from "react";

const UrlCheckComponent = () => {    
    const [isLoading, setIsLoading] = useState(false);
    const [isAPIWorking, setIsAPIWorking] = useState(true);

    const checkAPIStatus = async () => {
        try {
            const API_URL = process.env.REACT_APP_API_URL;
            await fetch(`${API_URL}/app`);
            setIsAPIWorking(true);
        } catch (err) {
            console.log(err);
            setIsAPIWorking(false);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        checkAPIStatus();
    }, [])    

    return (
        <table className="w-full max-w-xs border border-dark">
            <thead>
                <tr>
                    <th className="bg-gray-100 p-3 border border-dark text-left">URL</th>
                    <th className="bg-gray-100 p-3 border border-dark font-normal font-sans">Result</th>
                </tr>
            </thead>
            <tbody>
                <tr className="border border-dark">
                    <td className="border border-dark p-2">
                        <span className="font-bold">
                            At Home API
                        </span>
                    </td>   
                    {
                        isLoading ? 
                        <p className="text-center">loading..</p>
                        :
                        <td className={`border border-dark p-2 text-center ${isAPIWorking ? "text-green-600" : "text-red-600"} font-bold font-sans`}>
                            {isAPIWorking ? "PASS" : "Action needed"}
                        </td>                        
                    }
                </tr>
            </tbody>
        </table>
    )
}

export default UrlCheckComponent;