import React, { useRef } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import getStartedImg from "./../../../assets/images/homepage/homepage_brokenapart-03.png";
import banner1img from "./../../../assets/images/homepage/1.png";
import roundedImage from "./../../../assets/images/homepage/homepage_brokenapart-06.png";
import section2bg from "./../../../assets/images/homepage/homepage_brokenapart-08.png";
import selectABoxTitle from "./../../../assets/images/homepage/homepage_brokenapart-09.png";
import selectForFamilies from "./../../../assets/images/homepage/homepage_brokenapart-10.png";
import selectForGroups from "./../../../assets/images/homepage/homepage_brokenapart-12.png";
import sectionTwoBGImage from "./../../../assets/images/section-two-bg.png";
import NotifyMe from "../../shared/components/NotifyMe";
import { getHeightForNotifyMe } from "../../shared/utils/get-height";

const HomePage = () => {
    const messagesEndRef = useRef<any>(null);

    const handleGetStarted = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <>
            <section className="bg-aha-green py-6 md:py-0">
                <div className="md:flex container">
                    <div className="bg-aha-green text-white md:w-2/5 grid place-items-center">
                        <div className="space-y-4 ">
                            <div>
                                <div className="font-century-gothic-bold m-auto text-2xl lg:text-3xl xl:text-4xl get_started_title">
                                    <p>Bridging the gap</p>
                                    <p>between home and the</p>
                                    <p>speech therapy room</p>
                                </div>

                                <p className="text-center text-xl xl:text-2xl mt-4">
                                    We're here to show you how
                                </p>
                            </div>

                            <div
                                className="w-4/5 mx-auto cursor-pointer"
                                onClick={handleGetStarted}
                            >
                                <img src={getStartedImg} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="md:w-3/5">
                        <img
                            src={banner1img}
                            alt="Banner 1"
                            className="w-full object-contain pt-6 md:pt-0"
                        />
                    </div>
                </div>
            </section>
            <section
                className="bg-center bg-no-repeat bg-cover py-12"
                style={{
                    backgroundImage: `url(${section2bg})`,
                }}
            >
                <div className="container lg:flex">
                    <div className="w-4/5 md:w-4/5 lg:w-full xl:w-3/3">
                        <img src={roundedImage} alt="" />
                    </div>
                    <div className="flex flex-col justify-between">
                        <div></div>
                        {/* <div className="mb-10 text-2xl font-century-gothic-regular font-bold text-aha-orange md:mb-5 lg:mb-10  xl:mb-20 lg:text-4xl xl:text-5xl"> */}
                        <div className="pb-4  font-century-gothic-regular font-bold text-aha-orange ">
                            <p className="mb-3 text-2xl md:text-2xl lg:text-4xl xl:text-5xl">What is</p>
                            <p className="ml-12 text-2xl lg:text-4xl xl:text-5xl">At Home Articulation?</p>
                        </div>

                        <ul className="px-8 mt-8 md:mt-4 lg:mt-10  xl:mt-20 font-century-gothic-regular font-bold whatis__ul text-aha-black text-xl lg:text-2xl xl:text-3xl">
                            <li className="custom-bullet lg:ml-12 mb-10">
                                An app created by clinicians to help parents
                                practice speech with their child at home
                            </li>
                            <li className="custom-bullet lg:ml-7 mb-10">
                                A therapy and carryover tool for clinicians
                            </li>
                            <li className="custom-bullet mb-10 lg:-ml-10">
                                Designed for students Prek-6th grade
                            </li>
                        </ul>

                        <div></div>
                    </div>
                </div>
            </section>
            <section className="bg-aha-black py-12" ref={messagesEndRef}>
                <div className="container">
                    <div className="mb-6 flex justify-center">
                        <img src={selectABoxTitle} alt="" />
                    </div>
                    <div className="flex flex-col md:flex-row gap-8">
                        <div className="flex justify-center">
                            <Link className="max-w-4/5" to={"/group-registration"}>
                                <img src={selectForGroups} alt="" />
                            </Link>
                        </div>
                        <div className="flex justify-center">
                            <Link className="max-w-4/5" to={"/parent-registration"}>
                                <img src={selectForFamilies} alt="" />
                            </Link>
                        </div>
                        {/* <div> */}
                            {/* <Link to={"/clinician-registration"}>
                                <img src={selectForClinicians} alt="" />
                            </Link> */}
                        {/* </div> */}                        
                    </div>
                </div>
            </section>
            <section className="pt-12 bg-aha-light-blue ">
                <NotifyMe invertedTheme />

                <div
                    style={{
                        backgroundImage: `url(${sectionTwoBGImage})`,
                        minHeight: getHeightForNotifyMe(),
                    }}
                    className={"bg-cover bg-no-repeat bg-bottom"}
                ></div>
            </section>
        </>
    );
};

export default HomePage;
