import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Unity, useUnityContext } from "react-unity-webgl";
import carLoading from "../../../assets/images/loading/Car-Loading.png";
import girlLoading from "../../../assets/images/loading/Girl-Loading.png";
import owlLoading from "../../../assets/images/loading/Owl-Loading.png";
import rockingLoading from "../../../assets/images/loading/Rocking-Loading.png";
import teacherLoading from "../../../assets/images/loading/Teacher-Loading.png";
import tigerLoading from "../../../assets/images/loading/Tiger-Loading.png";
import LoaderComponent from "../../shared/components/LoaderComponent/LoaderComponent";
import AuthContext from "../../shared/context/authContext/AuthContext";
import { AnalyticOriginEnum } from "../../shared/enums/analytics-origin.enum";
import { AnalyticTypesEnum } from "../../shared/enums/analytics-types.enum";
import { ILevel } from "../../shared/interfaces/level.interface";
import { createIncrementalAnalytic } from "../../shared/services/analytics/analytics.service";
import ReadingContent from "./ReadingContent";
import { updateGameLog } from "../../shared/services/analytics/gameLog.service";
import { UpdateGameLog } from "../../shared/services/analytics/interfaces/gameLog.interface";
import { useIntercom } from "react-use-intercom";
import HelpModal from './components/HelpModal';
import { VideoTutorial } from "../../shared/interfaces/game.interface";

const WORD_LEVEL_INSTRUCTIONS =
    process.env[`REACT_APP_VIDEO_WORD_LEVEL_INSTRUCTIONS`];
const PHRASE_LEVEL_INSTRUCTIONS =
    process.env[`REACT_APP_VIDEO_PHRASE_LEVEL_INSTRUCTIONS`];
const READING_LEVEL_INSTRUCTIONS =
    process.env[`REACT_APP_VIDEO_READING_LEVEL_INSTRUCTIONS`];
const SENTENCE_LEVEL_INSTRUCTIONS =
    process.env[`REACT_APP_VIDEO_SENTENCE_LEVEL_INSTRUCTIONS`];
const VIDEO_INSTRUCTIONS = process.env[`REACT_APP_VIDEO_INSTRUCTIONS`];

const initLoadingImages = [
    carLoading,
    girlLoading,
    owlLoading,
    rockingLoading,
    teacherLoading,
    tigerLoading,
];

const Game = () => {
    const history = useHistory();

    const { state: authState } = useContext(AuthContext);

    const [loadingImages, setLoadingImages] = useState([...initLoadingImages]);
    const [currentLoadingImg, setCurrentLoadingImg] = useState(
        initLoadingImages[0]
    );
    const [clickCount, setClickCount] = useState(0);

    const [gameLogId, setGameLogId] = useState("");

    const [flashCards, setFlashCards] = useState([]);

    const [mediaAssets, setMediaAssets] = useState([]);

    const [readingContent, setReadingContent] = useState(null);

    const [setMode, setSetMode] = useState<any>(0);

    const [tipsAndTricks, setTipsAndTricks] = useState(null);

    const [isUnityReady, setIsUnityReady] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const gameAssets = JSON.parse(localStorage.getItem("gameAssets") || "{}");

    const gamePayload = JSON.parse(localStorage.getItem("gamePayload") || "{}");

    const levelSelected: ILevel | null = JSON.parse(
        localStorage.getItem("levelSelected") || "null"
    );

    const { shutdown: shutdownIntercom, boot: bootIntercom } = useIntercom();

    const gameConfig = gameAssets?.game?.gameFiles;

    const unityConfig = {
        loaderUrl: gameConfig.loader,
        dataUrl: gameConfig.data,
        frameworkUrl: gameConfig.framework,
        codeUrl: gameConfig.wasm,
    };

    const [stage, setStage] = useState();

    const {
        unityProvider,
        sendMessage,
        addEventListener,
        removeEventListener,
        isLoaded,
        unload,
    } = useUnityContext(unityConfig);

    const updateAnalytics = () => {
        createIncrementalAnalytic({
            type: AnalyticTypesEnum.NUMBER_OF_TRIALS,
            count: clickCount,
            origin: AnalyticOriginEnum.WEB,
        })
            .then()
            .catch();
    };

    const handleVideoClick = () => {
        // sendMessage("Mute", "SetMute", "true");
        setShowModal(true);
    };

    const handleCheckMarkClick = async () => {
        const value = clickCount + 1;
        setClickCount(value);
        try {
            updateGameLogData({
                id: gameLogId,
                sessionTrials: value,
                endSession: new Date(),
            });
        } catch (error) {
            console.log(error);
        }
    };
    const handleContactClickedClick = (event: any) => {
        window.location.href = "mailto:support@athomearticulation.com";
    };

    const getVideoUrl = (): any => {
        if (levelSelected) {
            const videoTutorial: VideoTutorial = gameAssets?.game?.videoTutorial;
            switch (levelSelected.title) {
                case "WORD":
                    return videoTutorial?.wordFileSrc || WORD_LEVEL_INSTRUCTIONS;
                case "PHRASE":
                    return videoTutorial?.phraseFileSrc || PHRASE_LEVEL_INSTRUCTIONS;
                case "READING":
                    return videoTutorial?.readingFileSrc || READING_LEVEL_INSTRUCTIONS;
                case "SENTENCE":
                    return videoTutorial?.sentenceFileSrc || SENTENCE_LEVEL_INSTRUCTIONS;
                default:
                    return VIDEO_INSTRUCTIONS;
            }
        }
        return VIDEO_INSTRUCTIONS;
    };

    const updateGameLogData = async (payload: UpdateGameLog) => {
        if (payload.id) {
            await updateGameLog({
                ...payload,
            });
        }
    };

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined = undefined;

        if (!isUnityReady || !isLoaded) {
            timer = setInterval(() => {
                if (loadingImages.length) {
                    setCurrentLoadingImg(
                        loadingImages.pop() || initLoadingImages[0]
                    );
                } else {
                    setLoadingImages(() => [...initLoadingImages]);
                    setCurrentLoadingImg(loadingImages.pop() ?? rockingLoading);
                }
            }, 2000);
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [loadingImages, isUnityReady, isLoaded]);

    useEffect(() => {
        if (isLoaded) {
            addEventListener("GetStage", setStage as any);
            addEventListener("InfoButtonClicked", () => handleVideoClick());
            addEventListener("CheckClicked", handleCheckMarkClick as any);
            addEventListener("ContactClicked", handleContactClickedClick);
        }

        return () => {
            removeEventListener("GetStage", setStage as any);
            removeEventListener("InfoButtonClicked", () => handleVideoClick());
            removeEventListener("CheckClicked", handleCheckMarkClick as any);
            removeEventListener("ContactClicked", handleContactClickedClick);
        };
    }, [addEventListener, removeEventListener, setStage, isLoaded, clickCount]);

    useEffect(() => {
        if (stage === 1) {
            if (clickCount) {
                updateAnalytics();
            }

            updateGameLogData({
                id: gameLogId,
                endSession: new Date(),
            });

            bootIntercom({
                userId: authState?.user?.id || "",
                email: authState?.user?.email || "",
                name: `${authState?.user?.firstname || ""} ${
                    authState?.user?.lastname || ""
                }`,
                customAttributes: { group_role: authState?.user?.group_role },
            });
            const gameAssets = JSON.parse(
                localStorage.getItem("gameAssets") || "{}"
            );
            if (gameAssets.gameLogId) {
                history.push("/app/game-feedback");
            } else {
                history.push("/app/lets-play");
            }
        }
    }, [stage]);

    useEffect(() => {
        shutdownIntercom();
        try {
            console.log("gameAssets: ", gameAssets);

            setGameLogId(gameAssets?.gameLogId);

            setFlashCards(gameAssets?.flashcards);

            setMediaAssets(gameAssets?.assets);

            setTipsAndTricks(gameAssets?.tipsAndTricks);

            setSetMode(gamePayload?.setMode);

            setReadingContent(gameAssets?.readingContent);

            if (!gameAssets?.readingContent?.length) {
                setIsUnityReady(true);
            }
        } catch (error) {
            console.log("🚀 ~ UNITY ERRORS:", error);
            toast.error(
                "Failed to load the game. Please try again in a while."
            );
        }
    }, []);

    useEffect(() => {
        let canvas: any;

        const onMouse = () => {
            if (canvas) {
                canvas[0].style["pointer-events"] = "auto";
            }
        };
        const onScroll = () => {
            if (canvas) {
                canvas[0].style["pointer-events"] = "none";
            }
        };
        if (isLoaded) {
            setTimeout(() => {
                sendMessage(
                    "FlashcardBackground",
                    "SetMode",
                    JSON.stringify({ mode: setMode, isIpad: 0, mediaAssets }) //0 for word, 1 for phrase, and 2 for sentence.
                );

                sendMessage(
                    "Flashcard",
                    "SetFlashCard",
                    JSON.stringify(flashCards)
                );

                sendMessage(
                    "TipsAndTricks",
                    "SetTipsAndTricks",
                    JSON.stringify(tipsAndTricks)
                );

                sendMessage(
                    "TipsAndTricks",
                    "SetTipsVisibility",
                    (authState.user?.subscriptionTier !== "FREE").toString()
                );

                if(gameAssets?.isUserFirstTimePlaying) {
                    sendMessage(
                        "NavHolder",
                        "OpenInstructions",
                        "true"
                    );
                }

                canvas = document.getElementsByClassName("unity-canvas") as any;
                if (canvas) {
                    document.addEventListener("wheel", onScroll, false);
                    document.addEventListener("mousemove", onMouse, false);
                }
            }, 500);
        }

        return () => {
            document.removeEventListener("wheel", onScroll, false);
            document.removeEventListener("mousemove", onMouse, false);
            unload();
        };
    }, [isLoaded]);

    return (
        <div className={`container p-10`}>
            {isUnityReady && !isLoaded ? (
                <div className="py-20 text-center loader">
                    <img
                        alt="loading"
                        style={{ objectFit: "contain" }}
                        src={currentLoadingImg}
                        className="image"
                    />
                    <span style={{ fontSize: "22px" }}>
                        <LoaderComponent />
                    </span>
                </div>
            ) : null}

            {isUnityReady ? (
                <Unity
                    unityProvider={unityProvider}
                    className={`w-full overflow-scroll unity-canvas`}
                />
            ) : (
                <>
                    {readingContent ? (
                        <ReadingContent
                            data={readingContent}
                            onFinish={() => {
                                setIsUnityReady(true);
                                window.scrollTo({
                                    top: 0,
                                });
                            }}
                        />
                    ) : null}
                </>
            )}
            
            <HelpModal 
                showModal={showModal}
                gameAssets={gameAssets}
                url={getVideoUrl()}
                level={levelSelected?.title || ''}
                sendMessage={sendMessage}
                onClose={(ev: any) => {
                    setShowModal(false);
                    sendMessage("NavHolder", "StartInfoButtonAnimation");
                    sendMessage("Mute", "SetMute", "false");
                }}/>
        </div>
    );
};

export default Game;
