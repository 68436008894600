import Player from "@vimeo/player";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { IPositionAndLevelInfoModalOption } from "../../shared/interfaces/modal-option.interface";

const PositionAndLevelInfoModal = ({
    showModal,
    onClose,
    activeInfoModal,
}: IPositionAndLevelInfoModalOption) => {
    const [isMobile] = useState(window.outerWidth < 768);

    useEffect(() => {
        if (activeInfoModal != "NONE") {
            let url = "";

            switch (activeInfoModal) {
                case "SOUND":
                    url = "https://vimeo.com/747402989";
                    break;
                case "WORDPOSITION":
                    url = "https://vimeo.com/747403314";
                    break;
                case "LEVEL":
                    url = "https://vimeo.com/747403623";
                    break;
                default:
                    url = "https://vimeo.com/747402989";
            }

            setTimeout(() => {
                new Player("InfoVideoContainer", {
                    url,
                    responsive: true,
                    autoplay: true,
                });
            }, 0);
        }
    }, [activeInfoModal]);

    return (
        <Modal
            isOpen={activeInfoModal != "NONE"}
            style={{
                content: {
                    top: "30%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -30%)",
                    width: isMobile ? "100%" : 500,
                    minHeight: 300,
                    padding: 40,
                    border: 0,
                },
            }}
        >
            <div className={"space-y-6 text-justify"}>
                {activeInfoModal === "SOUND" && (
                    <>
                        <div className={"my-2"}>
                            <div id="InfoVideoContainer"></div>
                        </div>
                    </>
                )}
                {activeInfoModal === "WORDPOSITION" && (
                    <>
                        <div className={"my-2"}>
                            <div id="InfoVideoContainer"></div>
                        </div>
                    </>
                )}
                {activeInfoModal === "LEVEL" && (
                    <>
                        <div className={"my-2"}>
                            <div id="InfoVideoContainer"></div>
                        </div>
                    </>
                )}
                <div className={"flex justify-center"}>
                    <button
                        className={
                            "bg-aha-green text-white py-4 px-8 text-xl hover:opacity-80 transition duration-200 ease-in-out"
                        }
                        onClick={() => {
                            onClose("NONE");
                        }}
                    >
                        Dismiss
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default PositionAndLevelInfoModal;
