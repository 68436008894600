import * as Yup from "yup";

export const withAshaSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required."),
    password: Yup.string().min(6).required("Password is required."),
    confirm_password: Yup.string()
        .min(6)
        .required("Confirm Password is required.")
        .equals([Yup.ref("password")], "Passwords must match"),
    clinician: Yup.object().shape({
        ashaNumber: Yup.string()
            .matches(/^[0-9]+$/, "Invalid ASHA #")
            .required("ASHA # is required.")
            .test(
                "len",
                "Invalid ASHA #",
                (val: any) => val?.toString().length === 8
            ),
    }),
});

export const withoutAshaSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required."),
    password: Yup.string().min(6).required("Password is required."),
    confirm_password: Yup.string()
        .min(6)
        .required("Confirm Password is required.")
        .equals([Yup.ref("password")], "Passwords must match"),
});
