import artworkImage from "./../../../assets/images/home/website_9-10.png";
import facebookIcon from "./../../../assets/images/facebookGreen.png";
import instagramIcon from "./../../../assets/images/instagramGreen.png";

export const BlogRight = () => {
    return (
        <div className="hidden md:flex items-center justify-center flex-col ">
            <img className="w-52" src={artworkImage} alt="" />
            <p className="mt-3 text-gray-500 text-lg text-center">
                An approachable
                <br /> tool built by
                <br />
                Speech-Pathologists
                <br />
                to bridge the gap for
                <br />
                families and clinicians,
                <br />
                At Home Articulation
                <br /> lightens the load of
                <br />
                clinicians, empowers
                <br /> parents, and engages
                <br />
                students
            </p>
            <hr className="my-4 w-6/12 border-2 border-aha-light-blue " />
            <p className="font-century-gothic-regular text-xl tracking-wide text-gray-500">
                FOLLOW US
            </p>
            <div className={"flex gap-4 mt-3"}>
                <a
                    href="https://www.facebook.com/At-Home-Articulation-109004648128424/"
                    target={"_blank"}
                >
                    <img
                        src={facebookIcon}
                        alt="Facebook"
                        className={"w-6 cursor-pointer"}
                    />
                </a>
                <a
                    href="https://instagram.com/athomearticulation?utm_medium=copy_link"
                    target={"_blank"}
                >
                    <img
                        src={instagramIcon}
                        alt="Instagram"
                        className={"w-6 cursor-pointer"}
                    />
                </a>
            </div>
        </div>
    );
};

export default BlogRight;
