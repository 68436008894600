import React from "react";
import styles from "./index.module.scss";
import borderImage from "../../../assets/images/home/website_9-13.png";
import story1 from "../../../assets/images/story1.jpg";
import story2 from "../../../assets/images/story2.jpg";

const OurStory = () => {
    return (
        <div className={"container py-10 w-full"}>
            <div
                className={
                    "text-7xl text-center md:text-left font-blanch-regular text-aha-orange "
                }
            >
                Our Story
            </div>
            <div className="my-8">
                <img src={borderImage} alt="" />
            </div>
            <div className="w-full flex flex-row relative">
                <div className="space-y-4 text-gray-500 md:w-3/6 w-full">
                    <div>
                        Annie and Laura are ASHA-certified speech-language
                        pathologists and experts in their field. They created At
                        Home Articulation to address one question: Is it
                        possible to make speech sound practice more successful
                        and fun at home? The two set to work to create features
                        that are exciting for parents, therapists, and children.
                        With At Home Articulation, kids can learn how to
                        articulate sounds correctly, parents can be empowered
                        with tips and tricks for an at-home practice system, and
                        clinicians can quickly send homework to their clients.
                        Annie and Laura are demystifying what speech looks like
                        with At Home Articulation by showing what the speech
                        therapy process looks like from start to finish.
                    </div>
                    <div>
                        The idea to bridge the gap between home and the speech
                        therapy room started with a late-night phone call
                        between the two sisters. When Annie discovered a
                        dietitian's protocol for picky eaters, she realized they
                        could apply the same empowering concepts to speech
                        therapy. The goal was to put all of the necessary
                        resources for speech therapy in one place, with
                        education for parents and tools for clinicians. They
                        used their skill sets and backgrounds as speech-language
                        pathologists to create this method of at-home learning.
                    </div>
                    <div>
                        After graduating from the University of Utah in 2008,
                        Laura immediately started working for the Davis School
                        District in preschools, K-6, and junior high. Laura
                        utilized standardized assessments to diagnose speech and
                        language disorders, including syntax, semantics,
                        articulation, social-language and stuttering. Through
                        this process she helped children on her caseload develop
                        important speech and language skills. After her move to
                        Georgia, she worked in private practice with childhood
                        apraxia of speech. Upon her return to Utah she worked
                        for charter schools where she continued the same speech
                        therapy practices with preschool through 6th grade
                        students. In this setting she also supervised
                        speech-language technicians as they provided speech
                        therapy.
                    </div>
                    <div>
                        Annie initially started working in schools following her
                        2014 graduation from the University of Utah. There, she
                        diagnosed and treated speech and language disorders in
                        children preschool through 6th grade. Her caseload
                        included a Life Skills classroom with kids on the autism
                        spectrum with varied communicative abilities.
                        Eventually, Annie switched to working in skilled nursing
                        facilities with the elderly. She provided treatment for
                        adults who suffered from strokes, traumatic brain
                        injuries and neurological disorders in order to
                        rehabilitate speech, language, voice, cognitive and
                        swallowing disorders. Annie then moved on to working in
                        outpatient and inpatient hospital facilities where she
                        would work on everything from voice and neurological
                        disorders like Parkinson's Disease and Dementia to
                        swallowing disorders. She also completed modified barium
                        swallow studies to diagnose and treat swallowing
                        disorders.
                    </div>
                    <div>
                        Both Annie and Laura specialize in diagnosing and
                        treating speech and language disorders. Their goal with
                        At Home Articulation is to empower parents, lighten the
                        load of clinicians, and engage students. These separate
                        but distinct purposes have driven them to bridge the gap
                        between home and the speech therapy room.
                    </div>
                </div>
                <div className="w-2/6 hidden md:block">
                    <div className="relative">
                        <img
                            className={styles.firstImage}
                            src={story1}
                            alt=""
                        />

                        <img
                            className={styles.secondImage}
                            src={story2}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurStory;
