import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { FC, useState } from "react";
import Modal from "react-modal";
import { changePaymentMethod } from "../../../shared/services/stripe/stripe.service";
import { toast } from "react-toastify";

interface IProps {
    show: boolean;
    onClose: () => void;
    onSuccess: () => void;
}

const isMobile = window.outerWidth < 768;

const CardUpdateModal: FC<IProps> = (props) => {
    const { show, onClose, onSuccess } = props;
    const stripe = useStripe();
    const stripeElements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [isCardReady, setIsCardReady] = useState(false);

    const onUpdate = async () => {
        setIsLoading(true);

        const card = stripeElements?.getElement(CardElement);
        if (card && stripe) {
            const response = await stripe.createPaymentMethod({
                type: "card",
                card: card,
            });

            if (response.paymentMethod) {
                const newId = response.paymentMethod.id;
                //TODO: CALL CHANGE PAYMENT METHOD

                try {
                    const response = await changePaymentMethod(newId);

                    if (response) {
                        toast.success("Payment method updated successfully");
                        onSuccess();
                    }
                } catch (error) {
                    toast.error(
                        "There was a problem changing the payment method. Please try again later."
                    );
                }
            }
        }
    };

    return (
        <Modal
            isOpen={show}
            onRequestClose={onClose}
            style={{
                content: {
                    ...modalStyles,
                    width: isMobile ? "100%" : "600px",
                },
            }}
        >
            <div>
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: "20px",
                            },
                        },
                    }}
                    onChange={(ev) => {
                        setIsCardReady(ev.complete);
                    }}
                    className="border py-1 px-2 border-primary-border"
                />
                <button
                    disabled={!isCardReady || isLoading}
                    className={
                        "mt-5 border border-aha-orange bg-aha-orange text-white py-1 px-6 hover:text-aha-orange hover:bg-white transition duration-200 ease-in-out"
                    }
                    onClick={() => onUpdate()}
                >
                    {isLoading ? "Updating..." : "Update"}
                </button>
            </div>
        </Modal>
    );
};

const modalStyles = {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: 50,
    border: 0,
    transform: "translate(-50%, -30%)",
    background: "#fff",
};

export default CardUpdateModal;
