import React, { FC, useState } from "react";
import "./index.scss";
import CardUpdateModal from "./CardUpdateModal";

interface IProps {
    type: "subscription" | "one-payment";
    cardNumber: string;
    cardBrand: string;
    onUpdate: () => void;
}

const CardInfo: FC<IProps> = (props) => {
    const { cardBrand, cardNumber, type, onUpdate } = props;
    const [cardUpdateModal, setCardUpdateModal] = useState(false);

    return (
        <>
            <div className="text-center py-5 px-10 border-2 h-5/6">
                <p className="text-xl font-bold mb-5">Payment Info</p>
                <p className="text-left mb-0">Card info:</p>
                <div className="border-2 py-2 px-5 mb-4">
                    <span>{cardBrand.toUpperCase()}</span> **********
                    <span>{cardNumber}</span>
                </div>
                {type === "subscription" && (
                    <p
                        className="cursor-pointer hover:underline"
                        onClick={() => {
                            setCardUpdateModal(true);
                        }}
                    >
                        update card
                    </p>
                )}
            </div>
            <CardUpdateModal
                show={cardUpdateModal}
                onClose={() => setCardUpdateModal(false)}
                onSuccess={onUpdate}
            />
        </>
    );
};

export default CardInfo;
