import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { loginFromAdminRequest } from "../../shared/services/auth/auth.service";
import AuthContext from "../../shared/context/authContext/AuthContext";
import { ACTIONS } from "../../shared/context/authContext/Constants";
import { updateAnalytics } from "../../shared/services/analytics/analytics.service";
import { clearLocalStorage } from "../../shared/services/auth/auth.service";
import { toast } from "react-toastify";

const LoginFromAdmin = () => {
    const { dispatch } = useContext(AuthContext);
    const history = useHistory();

    const handleInit = async () => {
        const token = new URLSearchParams(history.location.search).get("token");

        if (token) {
            try {
                const data = await loginFromAdminRequest(token);

                if (data.response) {
                    clearLocalStorage();

                    const user = data.response.user;

                    dispatch({
                        type: ACTIONS.LOGIN,
                        token: {
                            accessToken: data.response.accessToken,
                            refreshToken: data.response.refreshToken,
                        },
                    });

                    dispatch({
                        type: ACTIONS.SET_USER,
                        user: user,
                    });

                    await updateAnalytics({ lastLogin: Date() });

                    if (user && !user.firstLoginWeb) {
                        localStorage.setItem("preview-video", "1");
                    }

                    history.push("/app/lets-play");
                }
            } catch (error) {
                toast.error({
                    message:
                        "There was an error logging you in automatically, please try it yourself.",
                });
                history.push("/");
            }
        }
    };

    useEffect(() => {
        handleInit();
    }, []);

    return <></>;
};

export default LoginFromAdmin;
