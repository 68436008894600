import React from "react";

const ReadingContent = ({ data, onFinish }: any) => {
    return (
        <div>
            <div className="space-y-4">
                <div className="border-aha-green border-8 md:w-3/4 p-4 mx-auto space-y-4">
                    {data.map((content: any) => {
                        return (
                            <div className="relative" key={content.id}>
                                <img
                                    src={content.file}
                                    alt={content.title}
                                    className={"w-full"}
                                />
                                <div
                                    onClick={onFinish}
                                    className="test_class"
                                ></div>
                            </div>
                        );
                    })}
                </div>
                {/* <div className="flex justify-center">
                    <button
                        className="bg-aha-orange text-white text-2xl py-2 px-10"
                        onClick={onFinish}
                    >
                        Finish
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default ReadingContent;
