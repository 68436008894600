import React, { useEffect, useRef, useState } from "react";
import gplayLogo from '../../../assets/images/gplay-logo.svg'
import { useLocation } from "react-router-dom";

const RedirectToApp = () => {
    const location = useLocation();
    
    const ref = useRef<any>(null);
    const [timeLeft, setTimeLeft] = useState<number | null>(3);
    
    const getMobileOperatingSystem = () => {
        const userAgent = navigator.userAgent;
        
        if ( userAgent && /android/i.test(userAgent)) {
            return "Android";
        }
            
        if ( userAgent && /iPad|iPhone|iPod/.test(userAgent)) {
            return "iOS";
        }
    
        return "unknown";
    }

    const clicked = () => {
        if(ref.current){
            ref.current.click();            
            window.location.href = `athome://home${location?.search || ''}`;
        }
    }

    const goToStore = () => {
        const Os = getMobileOperatingSystem();
        
        if (Os === 'Android') {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.athomearticulation.app&hl=en&gl=US';
        }
        
        if(Os === 'iOS'){
            window.location.href = 'https://apps.apple.com/us/app/at-home-articulation/id1633674465?itsct=apps_box_link&itscg=30200';                
        }       
    }

    useEffect(() => {
        clicked();
    }, [])

    useEffect(() => {
        if(timeLeft === 0){
           setTimeLeft(null);
           goToStore();
        }

        if (!timeLeft) return;

        const intervalId = setInterval(() => {    
          setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
      }, [timeLeft]);

    const getAndroid = () => (
        <div className={"flex justify-center"}>
            <a href="https://play.google.com/store/apps/details?id=com.athomearticulation.app&hl=en&gl=US" 
            style={{ display: 'inline-block', overflow: 'hidden', borderRadius: 13, width: 250, height: 83}}>
                <img src={gplayLogo} style={{borderRadius: 13, width: 250, height: 75  }} alt="Download on the Play Store" />
            </a>
        </div>
    )

    const getiOS = () => (
        <button className={"pl-4"}>
            <a href="https://apps.apple.com/us/app/at-home-articulation/id1633674465?itsct=apps_box_badge&amp;itscg=30200" 
            style={{ display: 'inline-block', overflow: 'hidden', borderRadius: 13, width: 250, height: 83}}>
                <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1666915200" 
                alt="Download on the App Store" style={{borderRadius: 13, width: 250, height: 75  }} />
            </a>
        </button>
    )

    const timerCounting = () => (
        <div className={"flex justify-center"}>
            {
                timeLeft && timeLeft > 0 &&
                <div className={"flex flex-col items-center"}>
                    <p className={"text-red-500"}>
                        Couldn't open the app
                    </p>
                    <p className={"text-black"}>
                        Redirecting to store in {timeLeft}...
                    </p>
                </div>
            }
        </div>
    )

    const getStore = () => {
        const Os = getMobileOperatingSystem();
        
        if (Os === 'Android') {
            return (
                <>
                    {getAndroid()}
                    {timerCounting()}                    
                </>
            )
        }
        
        if(Os === 'iOS'){
            return (
                <>
                    {getiOS()}
                    {timerCounting()}                    
                </>
            )
        }

        return (
            <>
                <div className={"flex justify-center"}>
                    {getAndroid()}
                    {getiOS()}
                </div>
            </>
        )
    }    

    return (
        <div className={"pt-14 md:pt-40 pb-12"}>
            <button style={{ display: 'none' }} ref={ref} onClick={clicked}>
                <a href={`athome://home${location?.search || ''}`}>Go App</a>
            </button>
            {
                getStore()
            }
        </div>
    );
};

export default RedirectToApp;
