import React, { useRef, useState } from "react";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/solid";
import Modal from "react-modal";
import { IModalOption } from "../../../shared/interfaces/modal-option.interface";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { GameInstruction } from "../../../shared/interfaces/game.interface";

const InstructionsModal = ({
    showModal,
    onClose,
    gameTitle,
    levelTitle,
    instructions
}: IModalOption & { gameTitle: string; levelTitle: string, instructions: GameInstruction[] }) => {
    const [isMobile] = useState(window.outerWidth < 768);
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef<any>(null);

    const getText = (text:string) => {
        if(text.includes('{?}') || text.includes('{+}')) {
            let textWrapper = text.replaceAll('{?}', '<span style="font-size: 1.5rem" class="inline-flex justify-center text-green-600 border-solid border-2 rounded-full h-8 w-8 border-aha-dark-blue">?</span>');
            textWrapper = text.replaceAll('{+}', '<span style="font-size: 1.5rem" class="inline-flex justify-center text-green-600 border-solid border-2 rounded-full h-8 w-8 border-aha-dark-blue">+</span>');

            return <p dangerouslySetInnerHTML={{ __html: textWrapper }}></p>
        }

        return <p>{text}</p>;
    }

    const goNext = () => {
        sliderRef?.current?.slideToIndex(
            currentIndex === images.length - 1
                ? images.length - 1
                : currentIndex + 1
        );
    };

    const goPrev = () => {
        sliderRef?.current?.slideToIndex(
            currentIndex === 0 ? 0 : currentIndex - 1
        );
    };

    const goToIndex = (index: number) =>
        sliderRef?.current?.slideToIndex(index);

    const images: ReactImageGalleryItem[] = instructions?.map( intruction => {
        return {
            original: intruction.fileSrc
        }
    });

    return (
        <Modal
            isOpen={showModal}
            style={{
                content: {
                    top: "35%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    borderRadius: "15px",
                    transform: "translate(-50%, -30%)",
                    width: isMobile ? "100%" : "40%",
                    minWidth: isMobile ? "300px" : "600px",
                    padding: "10px 20px",
                    backgroundColor: 'rgba(229, 231, 235, 1)' 
                },
            }}
        >
            <div className="flex flex-row justify-center bg-gray-200">
                <div className="flex align-center">
                    {currentIndex !== 0 ? (
                        <div className="w-10 flex align-center">
                            <ChevronLeftIcon
                                onClick={goPrev}
                                className={"text-gray-500 cursor-pointer"}
                            />
                        </div>
                    ) : (
                        <div className="w-10"></div>
                    )}
                </div>
                <div className={"flex flex-col justify-between"}>
                    <div className="flex flex-row justify-between">
                        <div
                            className={
                                "font-bold font-liberation-sans text-xl text-center mt-4 mb-4"
                            }
                            style={{ color: "#444A63" }}
                        >
                            How to play {gameTitle}
                        </div>
                        <h1
                            className={
                                "font-black font-liberation-sans text-xl text-center mt-4 mb-4"
                            }
                            style={{ color: "#444A63" }}
                        >
                            {levelTitle} LEVEL
                        </h1>
                    </div>

                    <ImageGallery
                        ref={sliderRef}
                        startIndex={currentIndex}
                        onSlide={(idx) => setCurrentIndex(idx)}
                        items={images}
                        infinite={false}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showNav={false}
                    />

                    <div className="flex flex-col">
                        <h2
                            className="mt-4 mb-4 text-xl font-liberation-sans"
                            style={{ color: "#444A63" }}
                        >
                            {getText(instructions[currentIndex]?.title || '')}
                        </h2>
                        <p
                            className="mb-4 text-lg italic font-liberation-sans"
                            style={{ color: "#444A63" }}
                        >
                            {getText(instructions[currentIndex]?.legend || '')}
                        </p>
                    </div>
                    <div className="flex flex-row justify-between">
                        <div></div>
                        <div className="flex flex-row">
                            {images.map((_, idx) => (
                                <div
                                    key={idx}
                                    onClick={() => goToIndex(idx)}
                                    style={{ backgroundColor: idx <= currentIndex ? 'rgba(107, 114, 128, 1)' : 'rgba(209,213,219,1)' }}
                                    className={`border-gray-500 border-solid border-2 rounded-full h-6 w-6 mr-2 cursor-pointer`}
                                ></div>
                            ))}
                        </div>
                        {currentIndex !== images.length - 1 ? (
                            <div
                                className="font-liberation-sans italic text-lg cursor-pointer"
                                style={{ color: "#2076C5" }}
                                onClick={() => onClose(false)}
                            >
                                skip for now
                            </div>
                        ) : (
                            <div
                                className="font-liberation-sans italic text-lg cursor-pointer"
                                style={{ color: "#2076C5" }}
                                onClick={() => onClose(false)}
                            >
                                finished
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex align-center">
                    {currentIndex !== images.length - 1 ? (
                        <ChevronRightIcon
                            onClick={goNext}
                            className={"w-10 text-gray-500 cursor-pointer"}
                        />
                    ) : (
                        <div className="w-10"></div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default InstructionsModal;
