import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import WebRoutes from "../shared/routes/WebRoutes";
import LoginFromAdmin from "./auth/LoginFromAdmin";

const Root = () => {
    return (
        <Router>
            <Switch>
                <Route path="/login-from-admin" component={LoginFromAdmin} />
                <Route path="/" component={WebRoutes} />
            </Switch>
        </Router>
    );
};

export default Root;
