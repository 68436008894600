import { gql } from "@apollo/client";

const DiscountCode = `
    id
    name
    valid
	amount_off
	percent_off
	applies_to {
	    products
	}
`;

export const getStripeProductsQuery = gql`
    query getStripeProducts {
        getStripeProducts {
            product {
                id
                active
                caption
                description
                name
                url
                livemode
                subscriptionType
            }
            prices {
                id
                active
                currency
                nickname
                livemode
                unit_amount
                unit_amount_decimal
            }
        }
    }
`;

export const getStripeProductByIdQuery = gql`
    query getStripeProductById($input: GetStripeProductByIdInput!) {
        getStripeProductById(input: $input) {
            product {
                id
                active
                caption
                description
                name
                url
                livemode
            }
            prices {
                id
                active
                currency
                nickname
                livemode
                unit_amount
                unit_amount_decimal
            }
        }
    }
`;

export const createSubscriptionMutation = gql`
    mutation createSubscription($input: CreateSubscriptionInput!) {
        createSubscription(input: $input) {
            id
            subscriptionId
            paymentMethodId
            customerId
            user {
                id
                firstname
                lastname
                email
                role
                clinicianInfo {
                    ashaNumber
                    schoolDistrict
                    schoolName
                    city
                    state
                    privatePracticeName
                }
                subscriptionTier
                createdAt
                updatedAt
            }
            unsubscribeDate
        }
    }
`;

export const CancelSubscriptionMutation = gql`
    mutation cancelSubscription {
        cancelSubscription {
            message
        }
    }
`;

export const ReactivateSubscriptionMutation = gql`
    mutation reActivateSubscription {
        reActivateSubscription {
            message
        }
    }
`;

export const ChangePaymentMethod = gql`
    mutation changePaymentMethod($input: ChangePaymentMethodInput!) {
        changePaymentMethod(input: $input) {
            message
        }
    }
`;



export const GetDiscountCode = gql`
    query GetDiscountCode($input: GetDiscountCodeInput!) {
        GetDiscountCode(input: $input) {
           ${DiscountCode}
        }
    }
`;

export const GetAllDiscountCode = gql`
    query getAllDiscountCodes {
        getAllDiscountCodes {
           ${DiscountCode}
        }
    }
`;
