import { gql } from "@apollo/client";
import { UserFragment } from "../user/user.gql";

export const FlagFragment = gql`
    fragment Flags on Flags {
        id
        organizationOnboarding
        organizationClinicianOnboarding
        freeTrial3DaysReminding
        freeTrial7DaysReminding
        freeTrialLastReminding
        shouldShowFreeTrialEndReminding
    }
`;

export const GetFlags = gql`
    query GetFlags {
        GetFlags {
            show3DaysReminding
            show7DaysReminding
            showLastReminding
            showFreeTrialEndReminding
            user {
                ...User
            }
        }
    }
    ${UserFragment}
`;

export const UpdateFlags = gql`
    mutation UpdateFlags($input: UpdateFlagInput!) {
        UpdateFlags(input: $input) {
            ...Flags
        }
    }
    ${FlagFragment}
`;
