import React from "react";
import { Link } from "react-router-dom";
import signUpButtonGreen from "./../../../assets/images/parent-registration/parentlandingpage5-06.png";
import banner1img from "./../../../assets/images/clinician-registration/clinician landing page_split-03.png";
import confusedLadyImg from "./../../../assets/images/clinician-registration/clinician landing page_split-05.png";
import waveBg from "./../../../assets/images/clinician-registration/clinician-landing-page_split-08.png";

import bagRoundImg from "./../../../assets/images/clinician-registration/clinician landing page_split-09.png";
import clockRoundImg from "./../../../assets/images/clinician-registration/clinician landing page_split-10.png";
import peopleRoundImg from "./../../../assets/images/clinician-registration/clinician landing page_split-11.png";
import waveBgDotted from "./../../../assets/images/clinician-registration/clinician-landing-page_split-18.png";

import whatsSentBanner from "./../../../assets/images/clinician-registration/clinician landing page_split-21.png";
import mobilePreview from "./../../../assets/images/clinician-registration/clinician landing page_split-22.png";
import orangeButton from "./../../../assets/images/clinician-registration/clinician landing page_split-23.png";

import instructionPreview from "./../../../assets/images/clinician-registration/clinician landing page_split-29.png";
import youDontHaveToBanner from "./../../../assets/images/clinician-registration/clinician landing page_split-27.png";

import speechPracticePreview from "./../../../assets/images/clinician-registration/clinician landing page_split-30.png";
import yellowDotted from "./../../../assets/images/clinician-registration/clinician landing page_split-32.png";
import banner8 from "./../../../assets/images/clinician-registration/clinician landing page_split-34.png";
import signUpButtonOrange from "./../../../assets/images/clinician-registration/clinician landing page_split-35.png";
import dashedImage from "./../../../assets/images/dashed-orange.png";
import sectionTwoBGImage from "./../../../assets/images/section-two-bg.png";

import styles from "./index.module.scss";
import Testimonials from "../parent-registration/components/Testimonials";
import NotifyMe from "../../shared/components/NotifyMe";
import { AUTH_ROUTES } from "../../shared/routes/Constants";
import { getHeightForNotifyMe } from "../../shared/utils/get-height";

const REACT_APP_VIDEO_SEND_HOMEWORK =
    process.env[`REACT_APP_VIDEO_SEND_HOMEWORK`];

const videoHwUrl = `${REACT_APP_VIDEO_SEND_HOMEWORK}?autoplay=1&muted=1&loop=1&autopause=0`;

const ClinicianRegistration = () => {
    return (
        <>
            <section className="bg-aha-black pb-10 lg:pb-0">
                <div className="lg:flex container ">
                    <div className="bg-aha-black text-white w-5/5 lg:w-2/5 grid place-items-center ">
                        <div className="space-y-6 w-5/5 lg:w-4/5">
                            <div className="text-center mt-8 text-2xl font-century-gothic-bold space-y-2 lg:mt-0 lg:text-3xl xl:text-4xl">
                                <div>Your go-to</div>
                                <div>speech</div>
                                <div>carryover tool</div>
                            </div>
                            <div>
                                <Link to={`${AUTH_ROUTES.CLINICIAN_REGISTER}`}>
                                    <img src={signUpButtonGreen} alt="" />
                                </Link>
                            </div>
                            <div className="text-center text-xs tracking-widest">
                                NO CREDIT CARD REQUIRED
                            </div>
                        </div>
                    </div>
                    <div className="w-5/5 lg:w-3/5 ">
                        <img
                            src={banner1img}
                            alt="Banner 1"
                            className="w-full object-contain"
                        />
                    </div>
                </div>
            </section>

            <section
                className="py-12 bg-cover bg-center bg-no-repeat"
                style={{
                    backgroundImage: `url(${waveBg})`,
                }}
            >
                <div className="container">
                    <div className="w-5/5 mb-4 m-auto lg:flex lg:items-center lg:justify-center">
                        <div>
                            <p className="text-center text-2xl text-aha-black mb-2 tracking-wider xl:text-4xl">
                                Are you sending speech homework?
                            </p>
                        </div>
                        <div className="mb-5 mt-5 ml-10">
                            <img
                                className="w-60 xl:w-80 m-auto"
                                src={confusedLadyImg}
                                alt="confusedLadyImg"
                            />
                        </div>
                    </div>

                    <div className="mb-4 text-center text-xl text-aha-green font-bold mb-5 tracking-wider lg:text-3xl xl:text-5xl">
                        <p className="mb-4">
                            ...studies show that kids who practice at home
                        </p>
                        <p>move more quickly through the speech process</p>
                    </div>
                    <div className="flex justify-end">
                        <div className="w-2/4 text-center text-xl lg:text-2xl xl:text-4xl text-aha-black tracking-wider">
                            <p className="mb-2">So what's stopping you?</p>
                            <p className="mb-2">Probably the same things</p>
                            <p className="mb-2">that stopped us</p>
                        </div>
                    </div>
                </div>
                <div
                    className={`${styles.sectionTwo}  bg-no-repeat bg-center bg-contain`}
                    style={{
                        backgroundImage: `url(${waveBgDotted})`,
                    }}
                >
                    <div className="container">
                        <div className="mt-20 lg:mt-0 lg:flex lg:justify-between">
                            <div className="space-y-4 mt-10">
                                <div className="">
                                    <p className="text-center font-century-gothic-bold text-aha-green tracking-wider lg:text-3xl xl:text-5xl">
                                        Frustration
                                    </p>
                                </div>
                                <div>
                                    <img
                                        src={bagRoundImg}
                                        alt="bag"
                                        className={"w-52 m-auto xl:w-80"}
                                    />
                                </div>
                                <div className="text-center font-century-gothic-regular text-xl text-aha-black tracking-wider m-auto xl:text-3xl">
                                    <p className="mb-2">Despite your best</p>
                                    <p className="mb-2">effort, the homework</p>
                                    <p className="mb-2">you sent home ended</p>
                                    <p className="mb-2">up crumpled at the</p>
                                    <p className="mb-2">bottom of a backpack</p>
                                </div>
                            </div>
                            <div className="space-y-4 mt-40">
                                <div>
                                    <p className="text-center font-century-gothic-bold text-aha-green tracking-wider lg:text-3xl xl:text-5xl">
                                        Limited time
                                    </p>
                                </div>
                                <div>
                                    <img
                                        src={clockRoundImg}
                                        alt="clock"
                                        className={"w-52 m-auto xl:w-80"}
                                    />
                                </div>
                                <div>
                                    <div className="text-center font-century-gothic-regular text-xl xl:text-3xl text-aha-black tracking-wider m-auto">
                                        <p className="mb-2">
                                            Who really has time to
                                        </p>
                                        <p className="mb-2">
                                            write personalized
                                        </p>
                                        <p className="mb-2">
                                            instructions on every
                                        </p>
                                        <p className="mb-2">worksheet?</p>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-4 mt-60">
                                <div>
                                    <p className="text-center font-century-gothic-bold text-aha-green tracking-wider lg:text-3xl xl:text-5xl">
                                        Big caseloads
                                    </p>
                                </div>
                                <div>
                                    <img
                                        src={peopleRoundImg}
                                        alt="people"
                                        className={"w-52 m-auto xl:w-80"}
                                    />
                                </div>
                                <div>
                                    <div className="text-center font-century-gothic-regular text-xl xl:text-3xl text-aha-black tracking-wider m-auto">
                                        <p className="mb-2">Not to mention</p>
                                        <p className="mb-2">
                                            the 15 kids in the
                                        </p>
                                        <p className="mb-2">referral process</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-12">
                <div className="container">
                    <p className="text-center font-bold text-3xl xl:text-4xl text-aha-green mb-5 tracking-wider">
                        At Home Articulation takes care of homework for you
                    </p>
                    <div>
                        <img src={dashedImage} alt="" />
                    </div>
                    <div className="text-2xl text-center text-aha-black mt-5">
                        Send homework in less than 10 seconds directly to
                        parent's inbox!
                    </div>

                    <div className="flex justify-center mt-6">
                        <div className="w-3/5">
                            <div className="border-20 border-aha-light-blue aspect-w-16 aspect-h-9 bg-aha-light-blue">
                                <iframe
                                    id="SendHWVideo"
                                    src={videoHwUrl}
                                    allow="autoplay"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-12 -mb-8 lg:-mb-28 bg-aha-light-blue">
                <div className="container space-y-6">
                    <div>
                        <img
                            src={whatsSentBanner}
                            alt=""
                            className="w-3/5 mx-auto"
                        />
                    </div>
                    <div>
                        <img src={mobilePreview} alt="" />
                    </div>
                </div>
            </section>

            <section className="bg-aha-light-blue-500 pb-10 pt-10 lg:pt-32">
                <div>
                    <div className="text-center text-3xl text-aha-black mb-5">
                        Start sending homework today!
                    </div>
                    <div>
                        <Link to={`${AUTH_ROUTES.CLINICIAN_REGISTER}`}>
                            <img
                                src={orangeButton}
                                alt=""
                                className="w-3/5 m-auto"
                            />
                        </Link>
                    </div>
                </div>
            </section>

            <Testimonials />

            <section className="py-12 bg-aha-black">
                <div className="container lg:w-4/5">
                    <div className="lg:flex items-center">
                        <div className="mb-10 lg:mb-0">
                            <img
                                className="lg:w-5/6"
                                src={instructionPreview}
                                alt=""
                            />
                        </div>
                        <div className="space-y-8">
                            <div className="text-white text-center text-xl lg:text-4xl xl:text-5xl font-century-gothic-regular font-bold space-y-2">
                                <div>At Home Articulation</div>
                                <div>educates the parents</div>
                                <div>on your caseload...</div>
                            </div>
                            <div>
                                <img src={youDontHaveToBanner} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-12">
                <div className="container space-y-6">
                    <div>
                        <p className="text-center font-bold text-3xl lg:text-4xl text-aha-green mb-10 tracking-wider">
                            Speech practice kids actually want to do
                        </p>
                    </div>
                    <div className="">
                        <img src={speechPracticePreview} alt="" />
                    </div>
                    <div>
                        <img src={yellowDotted} alt="" />
                    </div>
                    <div className="text-center text-gray-500">
                        <div className="text-xl xl:text-3xl mb-4">
                            "The outcomes of gamification [are].. increased
                            motivation, engagement, and enjoyment.
                        </div>
                        <div className="italic text-xl xl:text-2xl">
                            -E-Learning and Digital Media
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-aha-light-blue-500">
                <div className="lg:flex">
                    <div className="w-5/5 lg:w-3/6 xl:w-2/6 mb-10 lg:mb-0">
                        <img
                            src={banner8}
                            alt="Banner 1"
                            className="w-full object-contain"
                        />
                    </div>
                    <div className="bg-aha-light-blue-500 w-5/5  lg:w-3/5 grid place-items-center ">
                        <div className="space-y-6 w-5/5  lg:w-4/5">
                            <div className="lg:w-4/5 m-auto">
                                <Link to={`${AUTH_ROUTES.CLINICIAN_REGISTER}`}>
                                    <img src={signUpButtonOrange} alt="" />
                                </Link>
                            </div>
                            <div className="text-center text-xl tracking-widest">
                                NO CREDIT CARD REQUIRED
                            </div>
                            <div className="text-center text-aha-black font-bold tracking-widest">
                                <div className="text-xl lg:text-2xl 2xl:text-3xl mb-3 ">
                                    What happens after 2 weeks?
                                </div>
                                <div className="text-xl lg:text-2xl 2xl:text-3xl">
                                    For continued access to premium features:
                                </div>
                            </div>

                            <div className="md:flex mb-10 lg:mb-0 space-y-6 md:space-y-0 gap-6 justify-center">
                                <div className="bg-white text-center ">
                                    <div className="px-4 py-8 text-xl lg:text-2xl 2xl:text-3xl text-aha-black">
                                        <div className="mb-6">
                                            <div className="tracking-wider">
                                                Annual subscription of
                                            </div>
                                        </div>
                                        <div className="text-6xl 2xl:text-7xl mb-6">
                                            $60
                                        </div>
                                        <div>(that's only $5 a month)</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-12 bg-aha-light-blue ">
                <NotifyMe invertedTheme />

                <div
                    style={{
                        backgroundImage: `url(${sectionTwoBGImage})`,
                        minHeight: getHeightForNotifyMe(),
                    }}
                    className={"bg-cover bg-no-repeat bg-bottom"}
                ></div>
            </section>
        </>
    );
};

export default ClinicianRegistration;
