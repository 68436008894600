import * as Yup from "yup";

export const contactSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // phone: Yup.string().required("Phone is required").length(10),
    phone: Yup.string()
    .matches(/^[0-9]+$/, "Invalid Phone Number")
    .required("Phone Number is required.")
    .test(
        "len",
        "Invalid Phone Number #",
        (val: any) => val?.toString().length === 10
    ),
    email: Yup.string().email("Invalid email").required("Email is required."),
    message: Yup.string().optional(),
    // confirm_password: Yup.string()
    //     .min(6)
    //     .required("Confirm Password is required.")
    //     .equals([Yup.ref("password")], "Passwords must match"),
    // clinician: Yup.object().shape({
    //     ashaNumber: Yup.string()
    //         .matches(/^[0-9]+$/, "Invalid ASHA #")
    //         .required("ASHA # is required.")
    //         .test(
    //             "len",
    //             "Invalid ASHA #",
    //             (val: any) => val?.toString().length === 8
    //         ),
    // }),
});
