export const ACTIONS = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    SET_TOKEN: "SET_TOKEN",
    SET_LOADING: "SET_LOADING",
    SET_ASSETS_LOADING: "SET_ASSETS_LOADING",
    SET_USER: "SET_USER",
    SET_EMAIL_STRIPE_CHECKOUT: "SET_EMAIL_STRIPE_CHECKOUT",
    SET_STUDENTS_UNDER_USER: "SET_STUDENTS_UNDER_USER"
};
