export const formatPrice = (value: number): string => {
    if (value) {
        const price = value.toString();
        return `$${price.slice(0, price.length - 2)}.${price.slice(
            price.length - 2
        )}`;
    }
    return "-";
};

export const getPricePerMonth = (value: number): string => {
    if (value) {
        const price = value.toString();
        const priceFormated = `${price.slice(
            0,
            price.length - 2
        )}.${price.slice(price.length - 2)}`;

        const final = Number(priceFormated) / 12;
        return final.toString();
    }
    return "";
};
